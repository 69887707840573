import { Country } from '@staycool/location';
import { Currency } from '../wallet/types';

export enum PROVIDERS {
    ALPS_BANK = 'alps-bank',
    ALPS_CASH = 'alps-cash',
    ALPS_ONLINE = 'alps-online',
    ALPS_WEBPAY = 'alps-webpay',
    ASTROPAY = 'astropay',
    AUTHIPAY_AIBMS_CARD = 'authipay-aibms-card',
    BLIXTPAY = 'blixtpay',
    BRAINTREE_PAYPAL = 'braintree-paypal',
    CARD_EYE = 'card-eye',
    CLEO = 'cleo',
    COOLPAY = 'coolpay',
    COOLPAY_CARD = 'coolpay-card',
    DEVCODE_BAMBORA = 'devcode-bambora',
    DEVCODE_BESTPAY = 'devcode-bestpay',
    DEVCODE_BILLSPAY = 'devcode-billspay',
    DEVCODE_CLEANPAY = 'devcode-cleanpay',
    DEVCODE_DIRECTA24_BANK = 'devcode-directa24-bank',
    DEVCODE_DIRECTA24_BCP = 'devcode-directa24-bcp',
    DEVCODE_DIRECTA24_PAGO_EFECTIVO = 'devcode-directa24-pago-efectivo',
    DEVCODE_DIRECTA24_SERVIPAG = 'devcode-directa24-servipag',
    DEVCODE_DIRECTA24_WEBPAY = 'devcode-directa24-webpay',
    DEVCODE_DIRECTA24_WESTERN_UNION = 'devcode-directa24-western-union',
    DEVCODE_ECOPAYZ = 'devcode-ecopayz',
    DEVCODE_HEXOPAY = 'devcode-hexopay',
    DEVCODE_INTERAC_COMBINED = 'devcode-interac-combined',
    DEVCODE_INTERAC_E_CASHOUT = 'devcode-interac-e-cashout',
    DEVCODE_INTERAC_E_TRANSFER = 'devcode-interac-e-transfer',
    DEVCODE_INTERAC_ONLINE = 'devcode-interac-online',
    DEVCODE_JETON_GO = 'devcode-jeton-go',
    DEVCODE_JETON_WALLET = 'devcode-jeton-wallet',
    DEVCODE_KLUWP_MASTERCARD = 'devcode-kluwp-mastercard',
    DEVCODE_KLUWP_VISA = 'devcode-kluwp-visa',
    DEVCODE_LUXONPAY = 'devcode-luxonpay',
    DEVCODE_MUCHBETTER = 'devcode-muchbetter',
    DEVCODE_NEOSURF_VOUCHER = 'devcode-neosurf-voucher',
    DEVCODE_QUICKBIT = 'devcode-quickbit',
    DEVCODE_SECURE_TRADING = 'devcode-secure-trading',
    DIRECTA24_BANK = 'directa24-bank',
    DIRECTA24_CARDS = 'directa24-cards',
    DIRECTA24_VOUCHERS = 'directa24-vouchers',
    EPRO_CASHLIB = 'epro-cashlib',
    EPRO_EASYWIRE = 'epro-easywire',
    EPRO_KWICK_N_GO = 'epro-kwick-n-go',
    EPRO_MASTERCARD = 'epro-mastercard',
    EPRO_PASS_N_GO = 'epro-pass-n-go',
    EPRO_VISA = 'epro-visa',
    EUTELLER = 'euteller',
    EVERY_PAY = 'every-pay',
    EVERY_PAY_APPLE_PAY = 'every-pay-apple-pay',
    EVERY_PAY_GOOGLE_PAY = 'every-pay-google-pay',
    FRONTNODE = 'frontnode',
    GLOBALPAY = 'globalpay',
    IN_APP_PURCHASE = 'in-app-purchase',
    IN_APP_PURCHASE_ANDROID = 'in-app-purchase-android',
    IN_APP_PURCHASE_IOS = 'in-app-purchase-ios',
    INTERAC_COMBINED = 'interac-combined',
    INTERAC_E_CASHOUT = 'interac-e-cashout',
    INTERAC_E_TRANSFER = 'interac-e-transfer',
    INPAY = 'inpay',
    JETON_LOCAL = 'jeton-local',
    LHV = 'lhv',
    LUQAPAY_COMMUNITY_BANK = 'luqapay-community-bank',
    LUQAPAY_JETON = 'luqapay-jeton',
    LUQAPAY_VOUCHER_CARD = 'luqapay-voucher-card',
    MONNET_BANK = 'monnet-bank',
    MUCHBETTER = 'muchbetter',
    NETELLER = 'neteller',
    NUVEI_APPLE_PAY = 'nuvei-apple-pay',
    NUVEI_CARD = 'nuvei-card',
    OZAN = 'ozan',
    PAYHOUND_BITCOIN = 'payhound-bitcoin',
    PAYHOUND_USDT = 'payhound-usdt',
    PAY_NEAR_ME_CARD = 'pay-near-me-card',
    PAY_NEAR_ME_VOUCHER = 'pay-near-me-voucher',
    PAYORO = 'payoro',
    PAYPAL = 'paypal',
    PAYRETAILERS = 'payretailers',
    PAYRETAILERS_BANK = 'payretailers-bank',
    PAYRETAILERS_CASH = 'payretailers-cash',
    PAYRETAILERS_WEBPAY = 'payretailers-webpay',
    PRONTO_PAGA_BANK = 'pronto-paga-bank',
    PRONTO_PAGA_PAYPHONE = 'pronto-paga-payphone',
    SEB = 'seb',
    SEPA = 'sepa',
    SEPA_B9S = 'sepa-b9s',
    SEPA_G1I = 'sepa-g1i',
    SEPA_G4E = 'sepa-g4e',
    SEPA_N3S = 'sepa-n3s',
    SIGHTLINE = 'sightline',
    SKRILL = 'skrill',
    SKRILL_CHILE_BANK = 'skrill-chile-bank',
    SKRILL_CHILE_CASH = 'skrill-chile-cash',
    SKRILL_PAYSAFE = 'skrill-paysafe',
    SKRILL_RAPID = 'skrill-rapid',
    SWED = 'swed',
    TESLA_COIL = 'tesla-coil',
    TRUELAYER = 'truelayer',
    TRUSTLY = 'trustly',
    TRUSTLY_DANSKE = 'trustly-deposit.bank.finland.daba_bankbutton',
    TRUSTLY_NORDEA = 'trustly-deposit.bank.finland.ndea_bankbutton',
    TRUSTLY_OP_POHJALA = 'trustly-deposit.bank.finland.okoy_bankbutton',
    TRUSTLY_SPANKKI = 'trustly-deposit.bank.finland.sban_bankbutton',
    TRUSTLY_SWEDBANK = 'trustly-swedbank',
    TRUSTLY_SWISH = 'trustly-swish',
    TRUSTLY_US = 'trustly-us',
    TSI = 'tsi',
    WORLDPAY = 'worldpay',
}

export enum PROVIDER_TYPE {
    DEPOSIT = 'DEPOSIT',
    WITHDRAWAL = 'WITHDRAWAL',
}

export type PaymentProvider = {
    aux: any;
    disclaimer: string;
    feeDays: number;
    feePercent: number;
    hideAmountSelector?: boolean;
    id: number;
    image: string;
    isDepositRequired: boolean;
    isDisabled: boolean;
    isFeeRequired: boolean;
    isShowingPaymentMethods: boolean;
    kycRestriction: PaymentProviderKycRestriction;
    maxAmount: any;
    minAmount: any;
    order: number;
    slug: PROVIDERS;
    time: string;
    title: string;
    type: PROVIDER_TYPE;
};

export const enum PaymentProviderKycRestriction {
    ASK_ID_VERIFICATION_ON_PAYMENT = 'Ask ID verification on payment',
}

export type Transaction = {
    id: string;
    state: TransactionState;
    type: TransactionType;
    walletTransactionId: string;
    cancelWithdrawalLock?: {
        hasCancelWithdrawalLock: boolean;
    };
    currency: Currency;
    user_id: string;
    external_transaction_id: string;
    created: string;
    description: string;
    gameName?: string;
    product: string;
    provider: PROVIDERS;
    amountUc: number;
    balance_uc: number;
};

export enum TransactionState {
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
    PENDING = 'PENDING',
    PAYOUT_FAILED = 'PAYOUT_FAILED',
}

export enum TransactionType {
    DEPOSIT = 'DEPOSIT',
    WITHDRAWAL = 'WITHDRAWAL',
}

export enum DepositStatus {
    AUTO_REFUNDED_3RD_PERSON_METHOD = 'auto-refunded-3rd-person-method',
    AUTO_REFUNDED_NAME_MISMATCH = 'auto-refunded-name-mismatch',
    CANCELLED = 'cancelled',
    COMPLETED = 'completed',
    FAILED = 'failed',
    PENDING = 'pending',
}

export enum PayoutStatus {
    FAILED = 'failed',
    PAYOUT_FAILED = 'payout_failed',
    PENDING = 'pending',
}

export type ExistingPaymentMethod = {
    id: string;
    number: string;
    expirationDate?: string;
    description: string;
    extra?: Record<string, any>;
};

export type PnpSettings = {
    country: Country;
    isBonusAllowed: boolean;
    status: PnpStatus;
    provider: PROVIDERS;
    minAmount: Record<Currency, number>;
    maxAmount: Record<Currency, number>;
};

type PnpStatus = 'disabled' | 'office' | 'enabled';

export interface ProviderRouting {
    id: number;
    market: string;
    configs: ProviderRoutingConfig[];
    type: PROVIDER_TYPE;
}

interface ProviderRoutingConfig {
    conditions: ProviderRoutingCondition[];
    destinationProviderId: number;
}

interface ProviderRoutingCondition {
    field: string;
    operator: string;
    value: string | number | boolean;
}
