import { getServiceUrl, httpGet, httpPost, httpPut } from '../services/api';
import {
    LIMIT_PERIOD,
    LIMIT_TYPE,
    LimitPeriod,
    ResponsibleGamingLimitConfig,
    ResponsibleGamingRestriction,
    SelfExclusionUntilFurtherNotice,
    TimeoutItemSource,
} from '../services/responsible-gaming/types';
import { ObjectValues } from '../services/ts-utils';
import { PRODUCT } from '../types/common';

const getUrl = (url) => getServiceUrl('responsible-gaming', url);

export async function getProductsTimeoutStatuses() {
    const url = getUrl('timeout/');
    return httpGet<{
        casino: TimeoutItem;
        sportsbook: TimeoutItem;
        poker: TimeoutItem;
        horse_racing: TimeoutItem;
        source: TimeoutItemSource;
        firstEndDate: string;
        selfExclusionUntilFurtherNotice: SelfExclusionUntilFurtherNotice | undefined;
    }>(url);
}

export function getResponsibleGamingRestrictions(currency: string) {
    const url = getUrl('restrictions/active');
    return httpPost<{ restrictions: ResponsibleGamingRestriction[]; config: ResponsibleGamingLimitConfig }>(url, {
        currency,
    });
}

export async function getUserReminderSettings(): Promise<{ interval_mins: number; is_active: boolean }> {
    const url = getUrl('reminder-settings/');
    return httpGet(url);
}

export async function setPanicTimeout() {
    const url = getUrl('timeout/panic/');
    return httpPost<void>(url);
}

export async function setSelfExclusionTimeout(period: number | 'untilFurtherNotice', isUntilFurtherNotice?: boolean) {
    const url = getUrl('timeout/self-exclusion/');
    return httpPost<void>(url, { period, isUntilFurtherNotice });
}

export async function setTimeoutForProduct(product, periodInDays) {
    const url = getUrl('timeout/');
    return httpPost<{ endDate?: string }>(url, { product, period: periodInDays });
}

export async function setSessionReminder(body: { is_active: boolean; interval_mins?: number }) {
    const url = getUrl('reminder-settings/');
    return httpPost<{ created: string; interval_mins: number; is_active: boolean; user_id: string }>(url, body);
}

export async function getUnapprovedReminders() {
    const url = getUrl('unapproved-reminders/');
    return httpGet<Reminder[]>(url);
}

export function prolongSessionReminder(unApprovedSessionReminder) {
    const url = getUrl('prolong-reminder/');
    return httpPost(url, { reminder_id: unApprovedSessionReminder.id });
}

export async function getLimitsByType(type, currency: string) {
    const url = getUrl('limits/');
    return httpGet<Record<string, { current_limit: any; next_limit: any }>>(url, { type, currency });
}

export async function setLimitByType(payload: {
    type: LIMIT_TYPE;
    period: LIMIT_PERIOD | LimitPeriod;
    amount: number;
    currency: string;
}) {
    const url = getUrl('limits/');
    return httpPost(url, payload);
}

export async function scheduleRemovalOfActiveLimitByTypeAndPeriod(type, period, currency: string) {
    const url = getUrl('limits/');
    return httpPost(url, { type, period, amount: 0, currency });
}

export async function scheduleCancellationOfUpcomingLimitByTypeAndPeriod(type, period) {
    const url = getUrl('limits/');
    return httpPut<Record<string, never>>(url, { type, period });
}

export async function saveSwedishLicenseFields(updateData: {
    currency: string;
    depositLimitAmountDay?: number;
    depositLimitAmountMonth?: number;
    depositLimitAmountWeek?: number;
    isOverRecommendedLimitConfirmed: boolean;
}) {
    const url = getUrl('sweden-license/');
    return httpPost(url, updateData);
}

export async function getUserAskResponsibleGaming() {
    const url = getUrl('user/');
    return httpGet<RGUser>(url);
}

export function sendLimitChangeRequest(params: {
    form: { question: string; answer: string }[];
    limitAmount: number;
    limitPeriod: string;
    limitType: LIMIT_TYPE | 'self exclusion';
}) {
    const url = getUrl('limit-change-request/send');
    return httpPost<void>(url, params);
}

export async function skipLimits(limitType: LIMIT_TYPE) {
    const url = getUrl('user/skip-limits/');
    return httpPost(url, { limitType });
}

export async function cancelSelfExclusionUntilFurtherNotice() {
    const url = getUrl('timeout/self-exclusion/until-further-notice/cancel/');
    return httpPost(url);
}

type TimeoutItem = {
    end_date: string;
    product: ObjectValues<typeof PRODUCT>;
    source: TimeoutItemSource;
};

type Reminder = {
    action: string;
    id: string;
    sessionStart: string;
    userId: string;
    wallet_data: any[];
    wallet_total: number;
};

type RGUser = {
    askDepositLimit: boolean;
    askLoginDurationLimit: boolean;
    askLossLimit: boolean;
};
