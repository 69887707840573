import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { LIMIT_TYPE, ResponsibleGamingRestrictionAction } from '../../../services/responsible-gaming/types';
import { sendLimitChangeRequest } from '../../../microservices/responsible-gaming';
import { translate } from '../../../services/translate';
import { logger } from '../../../services/logger';
import Ui2Form from '../../ui-2/form/Ui2Form';
import Ui2FormTextArea from '../../ui-2/form/text-area/Ui2FormTextArea';
import UiAlert from '../../ui/alert/UiAlert';
import UiGroup from '../../ui/group/UiGroup';
import UiButton from '../../ui/button/UiButton';
import Ui2FormCheckbox from '../../ui-2/form/checkbox/Ui2FormCheckbox';

interface Props {
    onClose: (action?: ResponsibleGamingRestrictionAction) => void;
    limitType: LIMIT_TYPE;
    limitAmount?: number;
    limitPeriod: string;
}

export default function ResponsibleGamingLockedLimitChangeRequest({
    onClose,
    limitType,
    limitPeriod,
    limitAmount,
}: Props) {
    const [hasRequested, setHasRequested] = useState(false);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const useFormMethods = useForm({
        defaultValues: {
            'question-0': undefined,
            'question-1': undefined,
            'question-2': undefined,
            'question-3': undefined,
            isTermAccepted: undefined,
        },
    });
    const { errors } = useFormMethods.formState;

    const questions = [
        'What are your thoughts about the current limit',
        'Has your income level increased lately',
        'Would gambling with a higher or no limit be affordable for you',
    ];

    async function send() {
        setIsLoading(true);
        try {
            const { ...answers } = useFormMethods.getValues();
            await sendLimitChangeRequest({
                form: questions.map((question, index) => ({
                    question,
                    answer: answers[`question-${index}`],
                })),
                limitAmount: limitAmount ?? 0,
                limitType,
                limitPeriod,
            });
            setHasRequested(true);
        } catch (error: any) {
            const failureMessage =
                error && error.message ? error.message : translate('Something went wrong', 'ui.common');

            setError(failureMessage);
            logger.error('ResponsibleGamingLockedLimitChangeRequest', 'send', error);
        }
        setIsLoading(false);
    }

    return (
        <Ui2Form useFormMethods={useFormMethods} onSubmit={send}>
            <p>{translate('Limit change request description', 'ui.account')}</p>
            {questions.map((question: string, index: number) => (
                <Ui2FormTextArea
                    disabled={hasRequested}
                    key={`question-${index}`}
                    name={`question-${index}`}
                    error={errors[`question-${index}`]}
                    label={`${index + 1}. ${translate(question, 'ui.account')}`}
                    maxLength={200}
                    rows={2}
                />
            ))}

            <Ui2FormCheckbox
                name="isTermAccepted"
                error={errors['isTermAccepted']}
                label={translate('I confirm that the above information is correct and true', 'ui.account')}
                required
            />

            {hasRequested && (
                <UiAlert success>{translate('Your Limit change request was sent successfully', 'ui.account')}</UiAlert>
            )}
            {error && <UiAlert failure>{error}</UiAlert>}
            <UiGroup>
                <UiButton block type="button" onClick={() => onClose()}>
                    {translate('Back', 'ui.common')}
                </UiButton>
                {!hasRequested && (
                    <UiButton block type="button" color="primary" isLoading={isLoading} isFormSubmitButton>
                        {translate('Send', 'ui.common')}
                    </UiButton>
                )}
            </UiGroup>
        </Ui2Form>
    );
}
