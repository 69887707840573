import React, { useState } from 'react';
import Wrapper from './styles';
import { getCasinoGameBackgroundUrl, getCasinoGameRoute } from '../../../services/casino/games';
import { formattedAmountWithCurrency, getActiveCurrency } from '../../../services/currency';
import UiButton from '../../ui/button/UiButton';
import { tryBonusCodeMultiFreeSpins } from '../../../services/bonuses/bonus';
import { handleMessageBonusClaimed } from '../../../services/crm';
import { useRouter } from '../../../services/router';
import UiModal from '../../ui/modal/UiModal';
import UiBox from '../../ui/box/UiBox';
import UiCloseButton from '../../ui/close-button/UiCloseButton';
import { translate } from '../../../services/translate';

interface FreeSpinBonusConfig {
    bonusId: string;
    coinValues: {
        [currency: string]: number;
    };
    gameId: number;
    gameImage: string;
    gameName: string;
    spinCount: number;
    bonusCode: string;
}

interface Props {
    freeSpinBonus: {
        [gameId: number]: FreeSpinBonusConfig;
    };
    isPreview?: boolean;
    messageId?: number;
}

export default function CrmFreeSpinBonus({ freeSpinBonus, isPreview = false, messageId }: Props) {
    const [error, setError] = useState<string>('');
    const [isClaiming, setIsClaiming] = useState<boolean>(false);
    const { navigateTo } = useRouter();

    const activeCurrency = getActiveCurrency();

    async function handleClaim(bonusConfig: FreeSpinBonusConfig) {
        if (isPreview) {
            return;
        }

        setIsClaiming(true);

        const { isSuccess, message } = await tryBonusCodeMultiFreeSpins(bonusConfig.bonusCode, false, {
            gameId: bonusConfig.gameId,
            gameName: bonusConfig.gameName,
            spinCount: bonusConfig.spinCount,
        });

        if (!isSuccess && setError) {
            setIsClaiming(false);
            return setError(message);
        }

        if (messageId) {
            await handleMessageBonusClaimed(messageId);
        }

        const gameRoute = getCasinoGameRoute({ id: Number(bonusConfig.gameId), name: bonusConfig.gameName });
        setIsClaiming(false);
        navigateTo(gameRoute);
    }

    return (
        <Wrapper>
            {Object.values(freeSpinBonus).map((bonusConfig, index) => (
                <div key={`free-spin-${index}`} className="free-spin-bonus-item">
                    <div className="free-spin-bonus-name">
                        <h3>{bonusConfig.gameName} </h3>
                    </div>
                    <div className="free-spin-bonus-thumbnail">
                        <img src={getCasinoGameBackgroundUrl(bonusConfig.gameImage)} />
                    </div>
                    <div className="free-spin-bonus-spins">
                        <p>{bonusConfig.spinCount} free spins with</p>
                    </div>
                    <div className="free-spin-bonus-amount">
                        <p>{formattedAmountWithCurrency(bonusConfig.coinValues[activeCurrency.toLowerCase()])}</p>
                    </div>
                    <div className="free-spin-bonus-claim">
                        <UiButton
                            color="primary"
                            block
                            size="large"
                            disabled={isClaiming}
                            onClick={() => handleClaim(bonusConfig)}
                        >
                            {translate('claim-your-bonus', 'ui.welcome')}
                        </UiButton>
                    </div>
                </div>
            ))}
            <UiModal open={!!error} onClose={() => setError('')}>
                <UiBox>
                    <UiCloseButton onClick={() => setError('')} className="close-button" />
                    <div dangerouslySetInnerHTML={{ __html: error }} />
                </UiBox>
            </UiModal>
        </Wrapper>
    );
}
