import React from 'react';
import UiButton from '../../../ui/button/UiButton';
import UiGroup from '../../../ui/group/UiGroup';
import { storageSet } from '../../../../services/storage';
import { ClientName } from '../../../../services/utils/types';
import { ENVIRONMENT_PARAMS, getClient, isBYOD, resetEnvironment } from '../../../../services/environment';
import { environment as environmentStore } from '../../../../stores/environment/environment';
import { useStore } from '../../../../hooks/useStore';
import { UiToggle } from '../../../ui/toggle/UiToggle';
import { ObjectValues } from '../../../../services/ts-utils';

export default function DevelopmentToolbarB2B() {
    const [environment, setEnvironment] = useStore(environmentStore);

    function updateEnvironmentParam(paramName: string, value: any) {
        setEnvironment({
            ...environment,
            [paramName]: String(value),
        });
        storageSet(paramName, value);
    }

    function setTheme(theme: ObjectValues<typeof ClientName> | 'fountainblue') {
        localStorage.setItem('THEME', theme);
    }

    function setIcons(client: ObjectValues<typeof ClientName> | 'fountainblue') {
        localStorage.setItem('ICONS', client);
    }

    return (
        <>
            <UiButton
                onClick={() => {
                    resetEnvironment();
                    window.location.reload();
                }}
            >
                Reset environment
            </UiButton>

            <UiGroup style={{ flexWrap: 'wrap' }}>
                <UiButton
                    color={getClient() === ClientName.COOLBET ? 'primary' : 'default'}
                    onClick={() => {
                        updateEnvironmentParam(ENVIRONMENT_PARAMS.CLIENT_NAME, ClientName.COOLBET);
                        setTheme(ClientName.COOLBET);
                        setIcons(ClientName.COOLBET);
                        window.location.reload();
                    }}
                >
                    Coolbet
                </UiButton>
                <UiButton
                    color={localStorage.getItem('THEME') === ClientName.STATION ? 'primary' : 'default'}
                    onClick={() => {
                        updateEnvironmentParam(ENVIRONMENT_PARAMS.CLIENT_NAME, ClientName.STATION);
                        setTheme(ClientName.STATION);
                        setIcons(ClientName.STATION);
                        window.location.reload();
                    }}
                >
                    Station
                </UiButton>
                <UiButton
                    color={localStorage.getItem('THEME') === 'fountainblue' ? 'primary' : 'default'}
                    onClick={() => {
                        updateEnvironmentParam(ENVIRONMENT_PARAMS.CLIENT_NAME, ClientName.STATION);
                        setTheme('fountainblue');
                        setIcons(ClientName.STATION);
                        window.location.reload();
                    }}
                >
                    Fountainblue
                </UiButton>
                <UiButton
                    color={localStorage.getItem('THEME') === ClientName.WYNNBET ? 'primary' : 'default'}
                    onClick={() => {
                        updateEnvironmentParam(ENVIRONMENT_PARAMS.CLIENT_NAME, ClientName.WYNNBET);
                        setTheme(ClientName.WYNNBET);
                        setIcons(ClientName.WYNNBET);
                        window.location.reload();
                    }}
                >
                    WynnBet
                </UiButton>
                <UiButton
                    color={localStorage.getItem('THEME') === ClientName.WYNNBET_NEVADA ? 'primary' : 'default'}
                    onClick={() => {
                        updateEnvironmentParam(ENVIRONMENT_PARAMS.CLIENT_NAME, ClientName.WYNNBET_NEVADA);
                        setTheme(ClientName.WYNNBET);
                        setIcons(ClientName.WYNNBET);
                        window.location.reload();
                    }}
                >
                    WynnBet Nevada
                </UiButton>
                <UiButton
                    color={localStorage.getItem('THEME') === ClientName.IVC ? 'primary' : 'default'}
                    onClick={() => {
                        updateEnvironmentParam(ENVIRONMENT_PARAMS.CLIENT_NAME, ClientName.IVC);
                        setTheme(ClientName.IVC);
                        setIcons(ClientName.IVC);
                        window.location.reload();
                    }}
                >
                    Island
                </UiButton>
                <UiButton
                    color={localStorage.getItem('THEME') === ClientName.CLUB8 ? 'primary' : 'default'}
                    onClick={() => {
                        updateEnvironmentParam(ENVIRONMENT_PARAMS.CLIENT_NAME, ClientName.CLUB8);
                        setTheme(ClientName.CLUB8);
                        setIcons(ClientName.CLUB8);
                        window.location.reload();
                    }}
                >
                    club8
                </UiButton>
            </UiGroup>
            <UiToggle
                label="BYOD"
                checked={isBYOD()}
                onChange={() => {
                    updateEnvironmentParam(ENVIRONMENT_PARAMS.IS_BYOD, !isBYOD());
                    window.location.reload();
                }}
            />
        </>
    );
}
