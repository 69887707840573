import React from 'react';
import random from 'lodash/random';
import UiAlert from '../../../ui/alert/UiAlert';

export default function DevelopmentToolbarQuotes() {
    const quotes = [
        `Talk is cheap. Show me the code ~ Linus Torvalds`,
        `A common fallacy is to assume authors of incomprehensible code will be able to express themselves clearly in comments ~ Kevlin Henney`,
        `Programming is like sex. One mistake and you have to support it for the rest of your life ~ Michael Sinz`,
        `Before software can be reusable, it first has to be usable ~ Ralph Johnson`,
        `Good software, like wine, takes time ~ Joel Spolsky`,
        `Good code is it’s own best documentation ~ Steve McConnell`,
        `Computers are good at following instructions, but not at reading your mind ~ Donald Knuth`,
        `Any fool can write code that a computer can understand. Good programmers write code that humans can understand ~ Martin Fowler`,
        `Measuring programming progress by lines of code is like measuring aircraft building progress by weight ~ Bill Gates`,
        `Don’t comment bad code. Rewrite it ~ Brian Kernighan`,
        `If debugging is the process of removing bugs, bugging must be the process of putting them in ~ Edsger Dijkstra`,
        `First, solve the problem. Then, write the code ~ John Johnson`,
        `Pane see sitt laivi. ~ Mihkel Kuusküll`,
        `No. ~ Tanel Koll`,
        `Mulle räigelt meeldib see error! ~ Sten Soomets`,
        `The most valuable thing you can make is a mistake - you can't learn anything from being perfect. ~ Adam Osborne`,
        `Don't patch bugs out, rewrite them out. ~ Anonymous`,
        `Any code of your own that you haven't looked at for six or more months might as well have been written by someone else. ~ Eagleson's law`,
        `Writing software as if we are the only person that ever has to comprehend it is one of the biggest mistakes and false assumptions that can be made. ~ Karolina Szczur`,
        `No one in the brief history of computing has ever written a piece of perfect software. It's unlikely that you'll be the first. ~ Andy Hunt`,
        `So much complexity in software comes from trying to make on thing do two things. ~ Ryan Singer`,
        `Later equals never. ~Le Blanc's law`,
        `Every great developer you know got there by solving problems they were unqualified to solve until the actually did it. ~ Patrick McKenzie`,
        `Code is like humor. When you have to explain it, it's bad. ~ Cory House`,
        `An evolving system increases its complexity unless work is done to reduce it. ~ Meir Lehman`,
    ];

    return <UiAlert>{quotes[random(0, quotes.length - 1)]}</UiAlert>;
}
