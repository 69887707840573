import React, { useEffect, useMemo } from 'react';
import { getNewPostsAmount } from '../../../microservices/l10n';
import { isFeatureAvailable } from '../../../services/feature';
import { getHomeRoute, getRoute, isActiveRoute } from '../../../services/router';
import { translate } from '../../../services/translate';
import { stores } from '../../../stores';
import { environment } from '../../../stores/environment/environment';
import { media } from '../../../stores/media/media';
import Svg from '../../svg/Svg';
import UiButton from '../../ui/button/UiButton';
import UiNavLink from '../../ui/nav-link/UiNavLink';
import CoolbetHeaderAnonymous from './anonymous/CoolbetHeaderAnonymous';
import CoolbetHeaderAuthenticated from './authenticated/CoolbetHeaderAuthenticated';
import CoolbetHeaderLanguages from './languages/CoolbetHeaderLanguages';
import Wrapper from './styles';
import CoolbetHeaderWarning from './warning/CoolbetHeaderWarning';
import { useStore } from '../../../hooks/useStore';
import { FEATURE } from '../../../services/types';
import classNames from 'classnames';

interface CoolbetHeaderLink {
    active?: boolean;
    badge?: number;
    available: boolean;
    icon: string;
    isSupportedScreen?: boolean;
    label: string;
    route: string;
}

interface Props {
    links?: CoolbetHeaderLink[];
}

export default function CoolbetHeader({ links: customLinks }: Props) {
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [dynamicPromotionsCount] = useStore(stores.dynamicPromotionsCount);
    const [newBlogPostsCount] = useStore(stores.blog.newBlogPostsCount);
    const [blogVisitDate] = useStore(stores.blog.blogVisitDate);
    const [{ isDesktop, isDesktopSmall }] = useStore(media);
    const MAX_DESKTOP_MENU_ITEMS = 7;
    const MAX_SMALL_DESKTOP_MENU_ITEMS = 5;
    const MAX_MENU_ITEMS = 4;
    const isTwoToneTheme = !isFeatureAvailable(FEATURE.THEME_SINGLE_TONE);
    const [{ CLIENT_NAME }] = useStore(environment);

    useEffect(() => {
        if (blogVisitDate) {
            getNewPostsAmount();
        }
    }, [blogVisitDate]);

    const links: CoolbetHeaderLink[] = [
        {
            label: translate('Sports', 'ui.header'),
            route: getRoute('sport'),
            icon: 'sports',
            available: isFeatureAvailable(FEATURE.SPORTSBOOK),
        },
        {
            label: translate('Casino', 'ui.header'),
            route: getRoute('casino.slots'),
            icon: 'casino-lobby-home',
            active:
                isActiveRoute(getRoute('casino.slots'), false) ||
                isActiveRoute(getRoute('casino.race'), false) ||
                isActiveRoute(getRoute('casino.live'), false),
            available: isFeatureAvailable(FEATURE.CASINO),
        },
        {
            label: translate('Horse Racing', 'ui.header'),
            route: getRoute('horse-racing'),
            icon: 'horse-racing',
            available: isFeatureAvailable(FEATURE.HORSE_RACING),
        },
        {
            label: translate('Poker', 'ui.header'),
            route: getRoute('poker'),
            icon: 'poker',
            available: isFeatureAvailable(FEATURE.POKER),
        },
        {
            label: translate('Virtual Sports', 'ui.common'),
            route: getRoute('virtual-sports'),
            icon: 'virtual-sports',
            available: isFeatureAvailable(FEATURE.VIRTUAL_SPORTS),
            isSupportedScreen: isDesktop,
        },
        {
            label: translate('Promotions', 'ui.header'),
            route: getRoute('promotions'),
            badge: dynamicPromotionsCount,
            icon: 'bonus-promotions',
            available: isFeatureAvailable(FEATURE.PROMOTIONS),
        },
        {
            label: translate('Blog', 'ui.header'),
            route: getRoute('blog'),
            badge: newBlogPostsCount,
            icon: 'blog',
            available: isFeatureAvailable(FEATURE.BLOG),
            isSupportedScreen: isDesktop && !isDesktopSmall,
        },
    ];

    const navLinks = useMemo(() => {
        if (customLinks) {
            return customLinks;
        }

        let newLinks = links.filter((link) => link.available);

        const desktopItemsCount = isDesktopSmall ? MAX_SMALL_DESKTOP_MENU_ITEMS : MAX_DESKTOP_MENU_ITEMS;
        const maxMenuItemsCount = isDesktop ? desktopItemsCount : MAX_MENU_ITEMS;

        let linksLen = newLinks.length;
        if (linksLen > maxMenuItemsCount) {
            newLinks = newLinks.filter((link) => {
                const isLinksCountGreaterThanMaxItems = linksLen > maxMenuItemsCount;
                const isSupportedScreen = !link.hasOwnProperty('isSupportedScreen') || link?.isSupportedScreen;

                const shouldRemoveLink = isLinksCountGreaterThanMaxItems && !isSupportedScreen;

                if (shouldRemoveLink) {
                    linksLen--;
                    return false;
                }

                return true;
            });
        }

        return newLinks;
    }, [isDesktop, isDesktopSmall, dynamicPromotionsCount, newBlogPostsCount]);

    return (
        <Wrapper className={`header-${CLIENT_NAME}`}>
            <CoolbetHeaderWarning />

            <div className="header-container">
                <UiNavLink to={getHomeRoute()} className="header-logo">
                    <img
                        src={`${window.coolb2b?.baseUrl || ''}/assets/images/logo/${CLIENT_NAME}.svg`}
                        alt={CLIENT_NAME}
                    />
                </UiNavLink>

                <div className="header-products-nav">
                    {navLinks.map((link) => (
                        <UiButton
                            type="tab"
                            key={link.route}
                            url={link.route}
                            size="default"
                            selected={link.active || isActiveRoute(link.route, false)}
                            icon={<Svg icon={link.icon} />}
                            isTwoToneTheme={isTwoToneTheme}
                            badge={link.badge}
                            className={classNames('menu-item', {
                                active: link.active || isActiveRoute(link.route, false),
                            })}
                        >
                            {link.label}
                        </UiButton>
                    ))}
                </div>

                <div className="header-user-controls">
                    {isFeatureAvailable(FEATURE.LANGUAGE_SELECTION) && <CoolbetHeaderLanguages />}
                    {isAuthenticated ? <CoolbetHeaderAuthenticated /> : <CoolbetHeaderAnonymous />}
                </div>
            </div>
        </Wrapper>
    );
}
