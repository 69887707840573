import React from 'react';
import Wrapper from './styles';
import { media } from '../../../../../stores/media/media';
import { useStore } from '../../../../../hooks/useStore';
import { translate } from '../../../../../services/translate';
import UiButton from '../../../../ui/button/UiButton';
import { environment } from '../../../../../stores/environment/environment';
import Snippet from '../../../../snippet/Snippet';

interface Props {
    isOpen: boolean;
    onSubmit: () => void;
    onClose: () => void;
}

export default function AccountVerificationProofOfPaymentModal({ isOpen, onSubmit, onClose }: Props) {
    const [{ isPhone }] = useStore(media);
    const [{ IMAGE_BASE_URL }] = useStore(environment);

    return (
        <Wrapper
            open={isOpen}
            className="new-modal"
            shouldDisplayCloseButton={false}
            title={translate('Proof of Payment', 'ui.account')}
            maxWidth={isPhone ? '80vw' : 400}
        >
            <div className="pop-instruction-image">
                <img src={`${IMAGE_BASE_URL}common/POP.png`} alt="pop-instruction" className="pop-image" />
            </div>
            <Snippet snippetKey="ui.account.pop-instruction" />

            <div className="button-section">
                <UiButton block onClick={onClose}>
                    {translate('Cancel', 'ui.common')}
                </UiButton>
                <UiButton color="primary" block onClick={onSubmit}>
                    {translate('Continue', 'ui.account')}
                </UiButton>
            </div>
        </Wrapper>
    );
}
