import React, { useEffect } from 'react';
import { translate } from '../../../../services/translate';
import { useStore } from '../../../../hooks/useStore';
import { stores } from '../../../../stores';
import { getSlug, loadCampaigns } from '../../../../services/sports/campaigns';
import { isFeatureAvailable } from '../../../../services/feature';
import { FEATURE } from '../../../../services/types';
import { getRoute, isActiveRoute } from '../../../../services/router';
import { isBetBuilderFeatureAvailable } from '../../../../services/bet-builder';
import SportMenuV2 from '../../menu-v-2/SportMenuV2';
import SportMenuV2NavLink from '../../menu-v-2/nav-link/SportMenuV2NavLink';
import { media } from '../../../../stores/media/media';

interface SportCampaignsMenu {
    isAvailable: boolean;
    id?: number;
    url: string;
    icon: string;
    label: string;
    isActive: boolean;
}

export default function SportCampaignsListV2() {
    const [campaigns] = useStore(stores.sports.campaigns);
    const [language] = useStore(stores.language);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const isCampaignFeaturedLinksAvailable = isFeatureAvailable(FEATURE.CAMPAIGN_FEATURED_LINKS);
    const [{ isPhone, isTablet }] = useStore(media);
    const isMainNavigationV2 = isFeatureAvailable(FEATURE.SPORT_MAIN_NAVIGATION_V2);

    useEffect(() => {
        loadCampaigns();
    }, [language, isAuthenticated]);

    function getList(): SportCampaignsMenu[] {
        return [
            {
                isAvailable: isCampaignFeaturedLinksAvailable && isFeatureAvailable(FEATURE.BOOSTED_ODDS),
                url: getRoute('sport.boosted'),
                icon: 'boosted',
                label: translate('Cool events', 'ui.sportsbook'),
                isActive: isActiveRoute(getRoute('sport.boosted'), false),
            },
            {
                isAvailable: isCampaignFeaturedLinksAvailable && isBetBuilderFeatureAvailable(),
                url: getRoute('sport.betbuilder'),
                icon: 'betbuilder',
                label: translate('Betbuilder', 'ui.sportsbook'),
                isActive: isActiveRoute(getRoute('sport.betbuilder'), false),
            },
            {
                isAvailable: isCampaignFeaturedLinksAvailable && isFeatureAvailable(FEATURE.HIGHEST_TURNOVER),
                url: getRoute('sport.highest-turnover'),
                icon: 'cash',
                label: translate('Highest turnover', 'ui.sportsbook'),
                isActive: isActiveRoute(getRoute('sport.highest-turnover'), false),
            },
            {
                isAvailable: isCampaignFeaturedLinksAvailable && isFeatureAvailable(FEATURE.SIMPLE_COMBO),
                url: getRoute('sport.simple-combo'),
                icon: 'combo-generator',
                label: translate('ui.sportsbook.combo-generator.combo-generator'),
                isActive: isActiveRoute(getRoute('sport.simple-combo'), false),
            },
            {
                label:
                    isPhone || isTablet
                        ? translate('sports.navigation.incoming-bets-button.short')
                        : translate('sports.navigation.incoming-bets-button'),
                isAvailable: isFeatureAvailable(FEATURE.INCOMING_BETS),
                isActive: isActiveRoute(getRoute('sport.incoming-bets'), false),
                url: getRoute('sport.incoming-bets'),
                icon: isMainNavigationV2 ? 'combo-generator' : 'incoming-bets',
            },
            {
                isAvailable: isFeatureAvailable(FEATURE.CONTESTS),
                isActive: isActiveRoute(getRoute('sport.contests'), false),
                url: getRoute('sport.contests'),
                icon: 'casino-race',
                label: translate('Contests', 'ui.sportsbook'),
            },
            {
                label:
                    isPhone || isTablet
                        ? translate('sports.navigation.winners-feed-button.short')
                        : translate('sports.navigation.winners-feed-button'),
                isAvailable: isFeatureAvailable(FEATURE.WINNERS_FEED),
                isActive: isActiveRoute(getRoute('sport.winners-feed'), false),
                url: getRoute('sport.winners-feed'),
                icon: isMainNavigationV2 ? 'crown' : 'trophy',
            },
            {
                label: translate('Parlay Cards', 'ui.sportsbook'),
                isAvailable: isFeatureAvailable(FEATURE.PARLAY_CARD),
                isActive: isActiveRoute(getRoute('sport.parlay-cards'), false),
                url: getRoute('sport.parlay-cards'),
                icon: 'parley-card',
            },
            ...(campaigns?.list ?? []).map((campaign) => {
                const url = `${getRoute('sport.campaign')}/${getSlug(campaign)}`;

                return {
                    isAvailable: true,
                    id: campaign.id,
                    url,
                    icon: 'promotions',
                    label: translate(`${campaign.id}`, 'ui.campaign.name'),
                    isActive: isActiveRoute(url, false),
                };
            }),
        ];
    }
    return (
        <SportMenuV2 label={translate('Campaigns', 'ui.sportsbook')}>
            {getList()
                .filter(({ isAvailable }) => isAvailable)
                .map(({ id, url, icon, label, isActive }) => (
                    <SportMenuV2NavLink key={id ?? url} to={url} icon={icon} label={label} isActive={isActive} />
                ))}
        </SportMenuV2>
    );
}
