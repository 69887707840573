import React, { ReactNode } from 'react';
import SimFooter from '../../components/sim/footer/SimFooter';
import Wrapper from './styles';
import { isFeatureAvailable } from '../../services/feature';
import { FEATURE } from '../../services/types';
import BannerMessage from '../../components/banner-message/BannerMessage';
import ScrollToTop from '../../components/scroll-to-top/ScrollToTop';
import MobileWebCheckBanner from '../../components/mobile-web-check-banner/MobileWebCheckBanner';
import CrmMessagePreview from '../../components/crm/message-preview/CrmMessagePreview';
import CmsMessageModal from '../../components/cms/message-modal/CmsMessageModal';
import AuthLoginModal from '../../components/auth/login/modal/AuthLoginModal';
import AuthPayAndPlayModal from '../../components/auth/pay-and-play/modal/AuthPayAndPlayModal';
import AuthInactivityModal from '../../components/auth/inactivity-modal/AuthInactivityModal';
import AuthProfileValidator from '../../components/auth/profile-validator/AuthProfileValidator';
import AccountDataForClosedAccount from '../../components/account/data/for-closed-account/AccountDataForClosedAccount';
import Toast from '../../components/toast/Toast';
import { closeToast } from '../../services/toast';
import { useStore } from '../../hooks/useStore';
import { media } from '../../stores/media/media';
import { stores } from '../../stores';
import { translate } from '../../services/translate';
import { getRoute } from '../../services/router';
import SimMobileMenu from '../../components/sim/mobile-menu/SimMobileMenu';
import SimHeader from '../../components/sim/header/SimHeader';

interface Props {
    children: ReactNode;
}

export default function SimLayout({ children }: Props) {
    const [{ isLargerThanTablet, isSmallerThanLaptop }] = useStore(media);
    const [toasts] = useStore(stores.toasts);

    const links = [
        {
            label: translate('Home', 'casino.ui'),
            route: `${getRoute('sim.games')}/1`,
            icon: 'casino-lobby',
            available: true,
        },
        {
            label: translate('casino.category.2.name'),
            route: `${getRoute('sim.games')}/2`,
            icon: 'slots',
            available: true,
        },
        {
            label: translate('casino.category.4.name'),
            route: `${getRoute('sim.games')}/4`,
            icon: 'table-games',
            available: true,
        },
        {
            label: translate('Promotions', 'ui.header'),
            route: getRoute('promotions'),
            icon: 'promotions',
            available: true,
        },
        {
            label: translate('Leaderboard', 'ui.header'),
            route: getRoute('sim.leaderboard'),
            icon: 'casino-race-star',
            available: true,
        },
    ];

    return (
        <Wrapper className="main">
            <div className="header">
                <SimHeader links={isLargerThanTablet ? links : []} />
            </div>
            <BannerMessage />
            <ScrollToTop>
                <div className="main-container">
                    {children}
                    {isFeatureAvailable(FEATURE.MOBILE_WEB_APP_INSTALL) && <MobileWebCheckBanner />}
                    <CrmMessagePreview />
                    <CmsMessageModal />
                </div>
            </ScrollToTop>
            {isSmallerThanLaptop && (
                <div className="main-phone-navigation">
                    <SimMobileMenu />
                </div>
            )}
            {isFeatureAvailable(FEATURE.LOGIN) && <AuthLoginModal />}
            <AuthPayAndPlayModal />
            {isFeatureAvailable(FEATURE.LOGIN) && <AuthInactivityModal />}
            <AuthProfileValidator />
            <AccountDataForClosedAccount />
            <Toast toasts={toasts} closeToast={closeToast} />
            {isFeatureAvailable(FEATURE.FOOTER) && <SimFooter />}
        </Wrapper>
    );
}
