import styled from 'styled-components';

type StyledProps = {
    $size?: number;
};

export default styled.img<StyledProps>`
    width: ${({ $size }) => $size}px;
    max-width: initial;
    &:before {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
    }
`;
