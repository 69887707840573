import Wrapper from './styles';
import React from 'react';

interface Props {
    isVisible: boolean;
    setIsVisible: (visibility) => void;
}

export default function UiVisibilityIcon({ isVisible, setIsVisible }: Props) {
    return (
        <Wrapper icon={isVisible ? 'eye-visible' : 'eye-invisible'} onClick={() => setIsVisible(!isVisible)} size={3} />
    );
}
