import React from 'react';
import { getRoute } from '../../../../services/router';
import { useStorage } from '../../../../services/storage';
import { stores } from '../../../../stores';
import moment from 'moment';
import { UiToggle } from '../../../ui/toggle/UiToggle';

export default function DevelopmentToolbarHorseRacing() {
    const [isHorseRacingDevMode, setIsHorseRacingDevMode] = useStorage(
        stores.horseRacingDevMode.isEnabled,
        'development.horse-racing-dev-mode',
    );

    const [mockedDate, setMockedDate] = useStorage(
        stores.horseRacingDevMode.mockedDate,
        'development.horse-racing-dev-mode.mocked-date',
    );

    if (!mockedDate) {
        setMockedDate(moment().subtract(1, 'day').format('yyyy-MM-DD'));
    }

    return (
        <>
            <UiToggle
                label="Horse racing dev mode"
                checked={isHorseRacingDevMode}
                onChange={() => {
                    setIsHorseRacingDevMode(!isHorseRacingDevMode);
                    window.location.href = getRoute('horse-racing');
                }}
            />
            {isHorseRacingDevMode && (
                <input
                    type="date"
                    value={mockedDate.toString()}
                    onChange={(event) => setMockedDate(event.target.value)}
                />
            )}
        </>
    );
}
