import { Licence } from '@staycool/location';
import moment from 'moment';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useStore } from '../../../../hooks/useStore';
import { scheduleRemovalOfActiveLimitByTypeAndPeriod } from '../../../../microservices/responsible-gaming';
import { formattedAmountWithCurrency, getActiveCurrency } from '../../../../services/currency';
import { DATE_YEAR_TIME_FORMAT, getFormattedDate } from '../../../../services/date';
import { isWithLicence } from '../../../../services/licence';
import { useGlobalModal } from '../../../../hooks/useGlobalModal';
import {
    getPeriodTranslationForLimitPeriod,
    isSpendingLimitByType,
    isTimeLimitByType,
    RG_ERROR_CODE,
} from '../../../../services/responsible-gaming';
import { LIMIT_TYPE, UserLimit } from '../../../../services/responsible-gaming/types';
import { translate } from '../../../../services/translate';
import { stores } from '../../../../stores';
import UiButton from '../../../ui/button/UiButton';
import UiPrompt from '../../../ui/prompt/UiPrompt';
import Wrapper from './styles';
import ResponsibleGamingLockedLimitChangeRequest from '../../locked-limit-change-request/ResponsibleGamingLockedLimitChangeRequest';

interface Props {
    limit: UserLimit;
    limits: UserLimit[];
    onLimitScheduledToEnd: (...args: unknown[]) => Promise<void> | void;
    onError: Dispatch<SetStateAction<string>>;
    onResetError: (...args: unknown[]) => void;
    isRemovingActiveLimitHidden?: boolean;
}

export default function ResponsibleGamingLimitStatus({
    limit,
    onLimitScheduledToEnd = async () => {},
    onError = () => {},
    onResetError = () => {},
    isRemovingActiveLimitHidden = false,
}: Props) {
    const [userSettings] = useStore(stores.sports.userSettings);
    const limitType = limit.activeLimit.type;
    const [isLimitRemovalConfirmationPromptVisible, setIsLimitRemovalConfirmationPromptVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { showModal, hideModal } = useGlobalModal();
    const [user] = useStore(stores.user);
    const dataTest = limitType + '-' + limit.activeLimit.period;
    const timeFormat = userSettings?.is_american_time_format ? 'MM-DD-YYYY hh:mm A' : 'DD.MM.YYYY HH:mm';

    function getSpendingLimitStatusMessage() {
        // TODO: limitEndDate should be limitPeriodEndDate. Robert will replace translation at a later time.
        return translate(`Remaining amount {{ remainingAmount }} until {{ limitEndDate }}`, 'ui.responsible-gaming', {
            remainingAmount: `${formattedAmountWithCurrency(limit.activeLimit.remaining_amount)}`,
            limitEndDate: getFormattedDate({
                date: limit.activeLimit.current_period_end_date,
                format: DATE_YEAR_TIME_FORMAT,
            }),
        });
    }

    function getTimeLimitStatusMessage() {
        const remainingFormattedDuration = moment
            .duration(limit.activeLimit.remaining_amount, 'minutes')
            .format('hh:mm', { trim: false });

        const remainingHours = remainingFormattedDuration.split(':')[0];
        const remainingMinutes = remainingFormattedDuration.split(':')[1];

        // TODO: limitEndDate should be limitPeriodEndDate. Robert will replace translation at a later time.
        return translate(
            `Remaining amount {{ remainingHours }} hrs {{ remainingMinutes }} min until {{ limitEndDate }}`,
            'ui.responsible-gaming',
            {
                remainingHours,
                remainingMinutes,
                limitEndDate: moment(limit.activeLimit.current_period_end_date).format(timeFormat),
            },
        );
    }

    function getLimitStatusTitle() {
        return translate(
            'Current {{ periodPhrase }} {{ type }} limit ({{ amount }}{{ unit }}/{{ period }})',
            'ui.responsible-gaming',
            {
                periodPhrase: getPeriodTranslationForLimitPeriod(limit.activeLimit.period),
                period: translate(limit.activeLimit.period, 'ui.account.rg'),
                amount: limit.activeLimit.amount,
                unit: limit.activeLimit.consumption_unit,
                type: translate(limitType, 'ui.responsible-gaming'),
            },
        );
    }

    function getIsRemovingActiveLimitAllowed() {
        if (isRemovingActiveLimitHidden) {
            return false;
        }
        const hasActiveLimit = Boolean(limit.activeLimit);
        const hasUpcomingLimit = Boolean(limit.upcomingLimit);
        const limitHasNoScheduledChanges = hasActiveLimit && !hasUpcomingLimit;
        const isRemovingActiveLimitAllowed = limitHasNoScheduledChanges && !limit.activeLimit.end_date;
        const isActiveDepositLimitWithoutChanges = limitType === LIMIT_TYPE.DEPOSIT && limitHasNoScheduledChanges;
        const isSwedishDepositLimit = isActiveDepositLimitWithoutChanges && isWithLicence(Licence.SWEDEN);
        const isRemovingLoginDurationLimitAllowed =
            limitType !== LIMIT_TYPE.LOGIN_DURATION || !isWithLicence(Licence.SWEDEN);

        return !isSwedishDepositLimit && isRemovingActiveLimitAllowed && isRemovingLoginDurationLimitAllowed;
    }

    async function removeActiveLimit() {
        setIsLimitRemovalConfirmationPromptVisible(false);
        setIsLoading(true);
        onResetError();

        try {
            await scheduleRemovalOfActiveLimitByTypeAndPeriod(limitType, limit.activeLimit.period, getActiveCurrency());
            await onLimitScheduledToEnd();
        } catch (error: any) {
            if (error.code === RG_ERROR_CODE.LIMIT_INCREASE_REMOVAL_LOCKED && user?.isTest) {
                openLimitChangeRequestModal(limit.activeLimit.type, limit.activeLimit.period);
            } else {
                onError(translate(error.message, 'ui.account'));
            }
        }

        setIsLoading(false);
    }

    function openLimitChangeRequestModal(limitType: LIMIT_TYPE, limitPeriod: string) {
        showModal(
            () => (
                <ResponsibleGamingLockedLimitChangeRequest
                    onClose={hideModal}
                    limitType={limitType}
                    limitPeriod={limitPeriod}
                />
            ),
            {
                onClose: hideModal,
                title: translate('Limit change request', 'ui.account'),
            },
        );
    }

    return (
        <>
            <Wrapper verticallyCentered>
                <div className="title">{getLimitStatusTitle()}</div>
                <em>
                    {isSpendingLimitByType(limitType) && getSpendingLimitStatusMessage()}
                    {isTimeLimitByType(limitType) && getTimeLimitStatusMessage()}
                </em>
                {getIsRemovingActiveLimitAllowed() && (
                    <UiButton
                        onClick={() => setIsLimitRemovalConfirmationPromptVisible(true)}
                        dataTest={dataTest}
                        isLoading={isLoading}
                    >
                        {translate('Remove limit', 'ui.responsible-gaming')}
                    </UiButton>
                )}
            </Wrapper>

            <UiPrompt
                open={isLimitRemovalConfirmationPromptVisible}
                message={translate(
                    'You are about to remove the {{ period }} {{ type }} limit. Please confirm.',
                    'ui.responsible-gaming',
                    {
                        type: translate(limitType, 'ui.responsible-gaming'),
                        period: getPeriodTranslationForLimitPeriod(limit.activeLimit.period),
                    },
                )}
                acceptPhrase={translate('Confirm', 'ui.account')}
                dismissPhrase={translate('Cancel', 'ui.account')}
                onAccept={removeActiveLimit}
                onDismiss={() => setIsLimitRemovalConfirmationPromptVisible(false)}
            />
        </>
    );
}
