import React from 'react';
import { storageGet, storageSet } from '../../../../services/storage';
import { UiToggle } from '../../../ui/toggle/UiToggle';

export default function DevelopmentToolbarOpenbet() {
    const isOpenbetBetbuilderAvailable = Boolean(storageGet('development.custom-betbuilder'));

    function handleBetbuilderToggle() {
        storageSet('development.custom-betbuilder', !isOpenbetBetbuilderAvailable);
        window.location.reload();
    }

    return (
        <>
            <UiToggle
                label="Openbet betbuilder"
                checked={isOpenbetBetbuilderAvailable}
                onChange={handleBetbuilderToggle}
            />
        </>
    );
}
