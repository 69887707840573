import { useEffect } from 'react';
import { getDevelopment, onMessage } from '../../microservices/dev-server';
import { stores } from '../../stores';

export default function Development() {
    useEffect(() => {
        getDevelopment().then((development) => {
            stores.development.set(development);
        });
        onMessage.subscribe(handleMessage);
    }, []);

    function handleMessage(message) {
        if (message.key === 'development') {
            stores.development.set(message.development);
        }
    }

    return null;
}
