import React from 'react';
import Wrapper from './styles';
import UiAlert from '../../ui/alert/UiAlert';

interface Props {
    error: { [key: string]: any };
}

export default function DevelopmentError(props: Props) {
    return (
        <Wrapper>
            <h3>{props.error.filePath}</h3>
            {props.error.errors.map((error, index) => (
                <div key={`${index}${error.message}`}>
                    <UiAlert failure>{error.message}</UiAlert>
                    {error.fix && <UiAlert success>Correct usage example: {error.fix}</UiAlert>}
                </div>
            ))}
        </Wrapper>
    );
}
