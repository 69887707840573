import forEach from 'lodash/forEach';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import some from 'lodash/some';
import uniq from 'lodash/uniq';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { useEffect } from 'react';
import {
    calculateTotalOdds,
    getMaxComboSelections,
    getTeaserTotalOdds,
    isBetSlipLoadingMarkets,
} from '../../../../services/sports/betslip';
import {
    addBetslipError,
    getErrorCode,
    removeAllBetslipErrorsOfCode,
} from '../../../../services/sports/betslip-errors';
import {
    AT_LEAST_TWO_SELECTIONS,
    COMBO_MARKET_ID,
    COMBO_NOT_ALLOWED_ERROR,
    INSUFFICIENT_FUNDS_ERROR,
    MAX_SELECTIONS_COMBO_ERROR,
    MAX_SELECTIONS_SYSTEM_ERROR,
    MAX_SELECTIONS_TEASER_ERROR,
    INVALID_STAKE_ERROR,
    SAME_MATCH_IN_BETSLIP_ERROR,
    SAME_MARKET_IN_BETSLIP_ERROR,
    systemBetTypeBySystemKey,
    TICKET_LIMIT_ERROR,
    ODDS_CLOSED_ERRORS_BACKEND,
    MIN_SELECTIONS_TEASER_ERROR,
    NOT_MAIN_LINE,
    INVALID_MAIN_LINE,
    TEASERS_WRONG_MARKET_TYPE,
    TEASER_CATEGORY_COMBINATION_NOT_ALLOWED,
    INCORRECT_AMOUNT_OF_SELECTIONS,
    INPLAY_NOT_ALLOWED,
    BET_BUILDER_NOT_ALLOWED_ERROR,
    MA_DISABLED_ERROR,
    MAX_SELECTIONS_COMBO_CARD_ERROR,
} from '../../../../services/sports/constants';
import {
    calculateSystemTotalPotentialReturn,
    calculateSystemTotalStake,
    getSystemCombinations,
} from '../../../../services/sports/system-bet-helpers';
import { stores, useStoreWithSelector } from '../../../../stores';
import moment from 'moment';
import { BET_TYPE, MarketInfo } from '../../../../services/sports/types';
import { logger } from '../../../../services/logger';
import { validateSameMatchTeasers } from '../../../../services/sports/betslip-fo-validations';
import { getMaxWin, getMinStake } from '../../../../services/sports/limits';
import { translate } from '../../../../services/translate';
import { useStore } from '../../../../hooks/useStore';
import { BonusType } from '../../../../services/bonuses/types';
import React from 'react';
import { useDebounce } from '../../../../hooks/useDebounce';
import type { BetSlipMinimalMarket } from '@staycool/sbgate-types';
import { handleSameMatchComboValidation } from '../../../../services/sports/betslip-validation';

function SportBetslipValidations() {
    const [betSlipMarketIdToOutcomeId] = useStore(stores.sports.betSlipMarketIdToOutcomeId);
    const [betSlipPlacingState] = useStore(stores.sports.betSlipPlacingState);
    const { isLoading } = betSlipPlacingState;
    const [marketInfoById] = useStore(stores.sports.marketInfoById);
    const [teaserSelectedPoint] = useStore(stores.sports.teaserSelectedPoint);
    const [teaserPayouts] = useStore(stores.sports.teaserPayouts);
    const [wallet] = useStore(stores.wallet);
    const [betSlipUserState] = useStore(stores.sports.betSlipUserState);
    const [isFreeBetSelected] = useStoreWithSelector(
        stores.sports.bonusBetsSelection,
        (state) => state[BonusType.FreeBet] || state[BonusType.FreeBetV2],
    );
    const { betType } = betSlipUserState;
    const [teaserMainLineErrorMarketIds] = useStore(stores.sports.teaserMainLineErrorMarketIds);
    const [teaserInvalidMainLineErrorMarketIds] = useStore(stores.sports.teaserInvalidMainLineErrorMarketIds);
    const [teaserStatusDisabledMarketIds] = useStore(stores.sports.teaserStatusDisabledMarketIds);
    const [betSlipErrorByMarketId] = useStore(stores.sports.betSlipErrorByMarketId);
    const genericErrors = betSlipErrorByMarketId[String(COMBO_MARKET_ID)] || [];
    const isManualAcceptanceDisabled = some(genericErrors, (error) => getErrorCode(error) === MA_DISABLED_ERROR);

    const stakeErrors = [
        BET_BUILDER_NOT_ALLOWED_ERROR,
        INVALID_STAKE_ERROR,
        TICKET_LIMIT_ERROR,
        INSUFFICIENT_FUNDS_ERROR,
    ];

    function validateSameCategoryNormalAndOutright(markets: MarketInfo[], marketId: string | number) {
        const outcomeMarket = markets.find((market) => market.id === +marketId) as MarketInfo;
        const hasBothOutrightAndNormalMatches = new Set(map(markets, (market) => market.match_type)).size > 1;
        if (hasBothOutrightAndNormalMatches) {
            const sameCategory =
                filter(markets, (market) => {
                    return (
                        outcomeMarket?.category_id === market.category_id &&
                        market.match_type !== outcomeMarket?.match_type
                    );
                }).length > 0;
            if (sameCategory) {
                return addBetslipError(outcomeMarket.id, COMBO_NOT_ALLOWED_ERROR);
            }
        }
    }

    const handleSameMatchComboValidationDebounced = useDebounce(
        async (marketsByMatchId: Record<string, BetSlipMinimalMarket[]>) => {
            await handleSameMatchComboValidation(marketsByMatchId);
        },
        300,
    );

    useEffect(() => {
        removeAllBetslipErrorsOfCode([
            SAME_MATCH_IN_BETSLIP_ERROR,
            SAME_MARKET_IN_BETSLIP_ERROR,
            COMBO_NOT_ALLOWED_ERROR,
            MAX_SELECTIONS_COMBO_ERROR,
            MAX_SELECTIONS_SYSTEM_ERROR,
            MAX_SELECTIONS_TEASER_ERROR,
            MIN_SELECTIONS_TEASER_ERROR,
            AT_LEAST_TWO_SELECTIONS,
            ODDS_CLOSED_ERRORS_BACKEND[0],
            NOT_MAIN_LINE,
            INVALID_MAIN_LINE,
            TEASERS_WRONG_MARKET_TYPE,
            INPLAY_NOT_ALLOWED,
            TEASER_CATEGORY_COMBINATION_NOT_ALLOWED,
            INCORRECT_AMOUNT_OF_SELECTIONS,
            translate('Something went wrong', 'ui.common'),
        ]);
        if (isBetSlipLoadingMarkets()) {
            return;
        }

        const marketsWithInfo = mapValues(betSlipMarketIdToOutcomeId, (outcomeId, marketId) => ({
            ...marketInfoById[marketId],
            selectedOutcomeId: outcomeId,
        }));

        forEach(marketsWithInfo, (market) => {
            if (market.betting_end && moment(market.betting_end).isBefore()) {
                addBetslipError(market.id, ODDS_CLOSED_ERRORS_BACKEND[0]);
            }

            if (market.status !== 'OPEN') {
                addBetslipError(market.id, ODDS_CLOSED_ERRORS_BACKEND[0]);
            }

            const isSingleOrComboCard = [BET_TYPE.SINGLE, BET_TYPE.COMBO_CARD].includes(betType);
            if (!isSingleOrComboCard && market.singles_only) {
                addBetslipError(market.id, COMBO_NOT_ALLOWED_ERROR);
            }

            if (betType === BET_TYPE.TEASER) {
                if (!market.teasers_enabled) {
                    addBetslipError(market.id, TEASERS_WRONG_MARKET_TYPE);
                } else if (market.in_play) {
                    addBetslipError(market.id, INPLAY_NOT_ALLOWED);
                } else if (teaserStatusDisabledMarketIds.includes(market.id)) {
                    addBetslipError(market.id, ODDS_CLOSED_ERRORS_BACKEND[0]);
                } else if (teaserMainLineErrorMarketIds.includes(market.id)) {
                    addBetslipError(market.id, NOT_MAIN_LINE);
                } else if (teaserInvalidMainLineErrorMarketIds.includes(market.id)) {
                    addBetslipError(market.id, INVALID_MAIN_LINE);
                }
            }
        });

        if (betType === BET_TYPE.TEASER) {
            const sameMatchForbiddenTeasers = validateSameMatchTeasers(marketsWithInfo);
            sameMatchForbiddenTeasers.forEach((marketId) => addBetslipError(marketId, SAME_MATCH_IN_BETSLIP_ERROR));
        }

        if ([BET_TYPE.SINGLE, BET_TYPE.BETBUILDER].includes(betType)) {
            return;
        }

        const marketsByMatchId = groupBy(Object.values(marketsWithInfo), (marketInfo) => marketInfo.match_id);

        if ([BET_TYPE.COMBO, BET_TYPE.SYSTEM].includes(betType)) {
            const marketsArray = Object.values(marketsByMatchId).flat();

            forEach(betSlipMarketIdToOutcomeId, (_, marketId) => {
                validateSameCategoryNormalAndOutright(marketsArray, marketId);
            });
            handleSameMatchComboValidationDebounced(marketsByMatchId);
        }

        const betTypeToSelectionLimits = {
            [BET_TYPE.COMBO]: {
                max: getMaxComboSelections(),
                maxError: MAX_SELECTIONS_COMBO_ERROR,
                minError: undefined,
                min: undefined,
            },
            [BET_TYPE.SYSTEM]: { max: 10, maxError: MAX_SELECTIONS_SYSTEM_ERROR, minError: undefined, min: undefined },
            [BET_TYPE.TEASER]: {
                max: 15,
                maxError: MAX_SELECTIONS_TEASER_ERROR,
                min: 2,
                minError: MIN_SELECTIONS_TEASER_ERROR,
            },
            [BET_TYPE.COMBO_CARD]: {
                max: 20,
                maxError: MAX_SELECTIONS_COMBO_CARD_ERROR,
                minError: undefined,
                min: undefined,
            },
        };
        const { max, maxError, min, minError } = betTypeToSelectionLimits[betType];
        const selectionCount = Object.keys(betSlipMarketIdToOutcomeId).length;

        const isMinSelectionsError = min && selectionCount < min;
        const isMaxSelectionsError = max && selectionCount > max;

        if (isMinSelectionsError) {
            addBetslipError(COMBO_MARKET_ID, minError);
        }

        if (isMaxSelectionsError) {
            addBetslipError(COMBO_MARKET_ID, maxError);
        }

        if (betType === BET_TYPE.TEASER && !isMinSelectionsError && !isMaxSelectionsError) {
            const categoryIds = uniq(
                Object.keys(betSlipMarketIdToOutcomeId).map(
                    (marketId) => marketInfoById[Number(marketId)]?.category_id,
                ),
            );
            const teaserTotalOdds = getTeaserTotalOdds();
            if (!teaserTotalOdds && categoryIds.length > 1) {
                addBetslipError(COMBO_MARKET_ID, TEASER_CATEGORY_COMBINATION_NOT_ALLOWED);
            } else if (!teaserTotalOdds && categoryIds.length === 1) {
                addBetslipError(COMBO_MARKET_ID, INCORRECT_AMOUNT_OF_SELECTIONS);
                logger.error(
                    'SportBetslipValidations',
                    'validateSameCategoryNormalAndOutright',
                    `Missing teaser payout odds for category ${categoryIds[0]} with selections amount ${selectionCount} and teaser points ${teaserSelectedPoint}`,
                );
            } else if (teaserTotalOdds <= 1) {
                addBetslipError(COMBO_MARKET_ID, INCORRECT_AMOUNT_OF_SELECTIONS);
            }
        }
    }, [
        betSlipMarketIdToOutcomeId,
        marketInfoById,
        betType,
        teaserMainLineErrorMarketIds,
        teaserStatusDisabledMarketIds,
        teaserSelectedPoint,
        teaserPayouts,
    ]);

    const { balance_uc: userBalance } = wallet || ({} as any);
    useEffect(() => {
        removeAllBetslipErrorsOfCode(stakeErrors);
        const { betType, stakeByMarketId, MAStakeByMarketId, systemStakes } = betSlipUserState;
        const minStake = getMinStake();
        if (!minStake) {
            return;
        }
        const marketIdsToTake = [BET_TYPE.BETBUILDER, BET_TYPE.SINGLE].includes(betType)
            ? Object.keys(betSlipMarketIdToOutcomeId)
            : [COMBO_MARKET_ID];
        marketIdsToTake.forEach((marketId) => {
            const sumStake = Number(stakeByMarketId[marketId] || 0);
            const sumMAStake = Number(MAStakeByMarketId[marketId] || 0);
            let summedStake = sumStake + sumMAStake;
            let isStakeInputDirty = stakeByMarketId[marketId];
            if (betType === BET_TYPE.SYSTEM) {
                const systemBets = getSystemCombinations(betSlipMarketIdToOutcomeId);
                isStakeInputDirty = some(systemStakes);
                summedStake = calculateSystemTotalStake(systemBets);
                Object.keys(systemBets).forEach((system) => {
                    const stakeKey = systemBetTypeBySystemKey[system];
                    const systemTypeStake: any = systemStakes[stakeKey] ? systemStakes[stakeKey] : undefined;
                    if (systemTypeStake !== undefined && systemTypeStake < minStake) {
                        addBetslipError(marketId, INVALID_STAKE_ERROR);
                    }
                });
            }
            if (isStakeInputDirty && summedStake < minStake && !isManualAcceptanceDisabled) {
                addBetslipError(marketId, INVALID_STAKE_ERROR);
            }
            if (userBalance !== undefined && !isFreeBetSelected && summedStake > userBalance && !isLoading) {
                addBetslipError(marketId, INSUFFICIENT_FUNDS_ERROR);
            }

            let winAmount = 0;
            if (betType === BET_TYPE.SYSTEM) {
                const systemBets = getSystemCombinations(betSlipMarketIdToOutcomeId);
                winAmount = calculateSystemTotalPotentialReturn(systemBets);
            } else {
                winAmount = summedStake * calculateTotalOdds(marketId);
            }
            const maxWin = getMaxWin();
            if (winAmount >= maxWin) {
                addBetslipError(marketId, TICKET_LIMIT_ERROR);
            }
        });
    }, [
        betSlipUserState,
        betSlipMarketIdToOutcomeId,
        userBalance,
        isFreeBetSelected,
        betType,
        teaserSelectedPoint,
        teaserPayouts,
    ]);

    return null;
}

export default React.memo(SportBetslipValidations);
