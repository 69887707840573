import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import keyBy from 'lodash/keyBy';
import uniqBy from 'lodash/uniqBy';
import { stringify } from 'query-string';
import { getGameLauncherConfig } from '../../microservices/casino-integrations';
import { getLiveLobbyMetaData } from '../../microservices/casino-live-lobby';
import { stores } from '../../stores';
import { casino } from '../../stores/casino';
import { environment } from '../../stores/environment/environment';
import { getStoreValue } from '../../stores/store/utils';
import { requestLogin } from '../auth';
import { getActiveCurrency } from '../currency';
import { isB2B } from '../environment';
import { isMobileApp, NativeMessageEventType, sendNativeEvent } from '../mobile-app';
import { AppType } from '../mobile-app/types';
import { getRoute } from '../router';
import { isTestUser } from '../user';
import { mapCasinoCategories } from './categories';
import { getCasinoSubProviderName, mapCasinoProviders } from './providers';
import {
    CasinoCategory,
    CasinoGame,
    CasinoGameLauncher,
    CasinoGameMetaData,
    CasinoGameType,
    CasinoMaintenance,
    CasinoProviders,
    CasinoSubProvider,
    LiveGameMetaData,
} from './types';
import { isFeatureAvailable } from '../feature';
import { FEATURE } from '../types';

export const LAST_WINNINGS_MAX_SIZE = 7;
export const LAST_WINNINGS_CRASH_MAX_SIZE = 5;

export const CRAZY_TIME_FEATURE = 'crazytime';

export const INFINITE_SEATS_FEATURE = 'infinite seats';

export const CasinoInternalBaccaratResults = {
    PLAYER: 'P',
    DEALER: 'B',
    TIE: 'T',
    HOME: 'H',
    AWAY: 'A',
    DRAW: 'D',
    LEFT: 'L',
    RIGHT: 'R',
    SUITED_TIE: 'S',
    DRAGON: 'Dn',
    TIGER: 'Tr',
} as const;

export const CrazyResults = {
    NUM_1: '1',
    NUM_2: '2',
    NUM_5: '5',
    NUM_10: '10',
    CA: 'ca',
    PA: 'pa',
    FL: 'fl',
    FL_R: 'fl_r',
    FL_B: 'fl_b',
    BO: 'bo',
} as const;

const LIVE_LOBBY_PROVIDERS = [
    CasinoProviders.EVOLUTION,
    CasinoProviders.PRAGMATIC_PLAY,
    CasinoProviders.MICROGAMING,
    CasinoProviders.STAKELOGIC,
];

export interface OnAirEntertainmentMessage {
    Name: string;
    Body: {
        gameId: string;
    };
    Type: string;
    Origin: string;
    SenderId: string;
    Id: string;
    Bridge: string;
}

export enum CasinoGameProperties {
    LINES_SPECIAL = 1,
    RTP_SPECIAL = 2,
    X_WIN_SPECIAL = 53,
}

export interface CasinoGameFull extends CasinoGame {
    description?: string;
    linesComment?: string;
    rtpComment?: string;
    maxWinMultiplierComment?: string;
}

export function mapCasinoGames(): void {
    const games = getStoreValue<CasinoGame[]>(casino.filteredGames);
    const gamesMap = getStoreValue<{ [key: number]: CasinoGame }>(casino.gamesById);
    const allGames = getStoreValue(casino.allGames);

    casino.gamesBySlug.set(keyBy(games, (game) => getCasinoGameSlug(game.name, game.id)));
    casino.gamesBySlugOld.set(keyBy(games, (game) => getCasinoGameSlugOld(game.name)));
    casino.gamesByServerId.set(keyBy(games, 'serverGameId'));
    casino.gamesByTableId.set(keyBy(games, 'tableId'));
    casino.gamesByExternalId.set(keyBy(games, 'externalId'));

    const gamesByProviderAndServerId = {};
    const gamesByProviderAndTableId = {};
    const allGamesByProviderAndServerId = {};
    const allGamesByExternalId = {};

    for (const game of games) {
        if (!gamesByProviderAndServerId[game.providerId]) {
            gamesByProviderAndServerId[game.providerId] = {};
        }
        gamesByProviderAndServerId[game.providerId][game.serverGameId] = game;

        if (game.tableId) {
            if (!gamesByProviderAndTableId[game.providerId]) {
                gamesByProviderAndTableId[game.providerId] = {};
            }
            gamesByProviderAndTableId[game.providerId][game.tableId] = game;
        }
    }

    for (const game of allGames) {
        if (!allGamesByProviderAndServerId[game.providerId]) {
            allGamesByProviderAndServerId[game.providerId] = {};
        }
        allGamesByProviderAndServerId[game.providerId][game.serverGameId] = game;

        if (game.externalId) {
            allGamesByExternalId[game.externalId] = game;
        }
    }

    casino.gamesByProviderAndServerId.set(gamesByProviderAndServerId);
    casino.gamesByProviderAndTableId.set(gamesByProviderAndTableId);
    casino.allGamesByProviderAndServerId.set(allGamesByProviderAndServerId);
    casino.allGamesByExternalId.set(allGamesByExternalId);

    const gamesByCategoryId = {} as { [key: number]: CasinoGame[] };

    getStoreValue<CasinoCategory[]>(casino.categories).forEach((category) => {
        gamesByCategoryId[category.id] = category.games.reduce((categoryGames, gameId) => {
            if (gamesMap[gameId] && (gamesMap[gameId].isPublic || isTestUser())) {
                categoryGames.push(gamesMap[gameId]);
            }
            return categoryGames;
        }, [] as CasinoGame[]);
    });

    casino.gamesByCategoryId.set(gamesByCategoryId);
}

export function setCasinoGameMode(isPlayForReal: boolean): void {
    if (isPlayForReal && !getStoreValue(stores.isAuthenticated)) {
        requestLogin();
        return;
    }

    casino.playForReal.set(isPlayForReal);
}

export function getCasinoGameSlug(name: string, id: number): string {
    return name
        .toLowerCase()
        .replace(/#|_|:|\.|;|,|!|^|\*|'|`|\?|’|%/g, '')
        .replace(/\/|\\/g, '-')
        .split(' ')
        .join('-')
        .concat(`-${id}`);
}

/**
 * @deprecated try not to use. Exists for old emails and promotions that contain game urls.
 */
function getCasinoGameSlugOld(name: string): string {
    return name
        .toLowerCase()
        .replace(/#|_|:|\.|;|,|!|^|\*|'|`|’/g, '')
        .split(' ')
        .join('-');
}

export function searchCasinoGames(searchWord: string): CasinoGame[] {
    if (!searchWord) {
        return [];
    }

    const filterRegex = /[^a-zA-Z0-9 ]/g;
    const searchWordModified = searchWord.replace(filterRegex, '').toLowerCase();

    const games: CasinoGame[] = getStoreValue(casino.allGames).filter((game) => game.isPublic || isTestUser());
    return uniqBy(
        [
            ...games.filter((game) => game.name.replace(filterRegex, '').toLowerCase().startsWith(searchWordModified)),
            ...games.filter((game) => game.name.replace(filterRegex, '').toLowerCase().includes(searchWordModified)),
        ],
        'id',
    );
}

export function getGamesByThemeId(themeId: number | undefined): CasinoGame[] {
    if (!themeId) {
        return [];
    }
    return getStoreValue(casino.allGames).filter(
        (game) => (game.isPublic || isTestUser()) && game.themes.includes(themeId),
    );
}

export function getGameImageUrl(imageName: string, imageWidthInPx = 300): string {
    return getGameImageUrlForImage(imageName, {
        dpr: Math.min(window.devicePixelRatio, 2),
        w: imageWidthInPx,
        auto: 'format',
    });
}

export function getSubproviderLogoUrl(subproviderId: number, imageWidthInPx = 300): string {
    return getSubproviderLogoUrlForLogo(subproviderId, {
        dpr: Math.min(window.devicePixelRatio, 2),
        w: imageWidthInPx,
        auto: 'format',
    });
}

export function getGameImageUrlForImage(imageName: string, params): string {
    const url = isB2B()
        ? `${getStoreValue(environment).IMAGES_HOST}${getStoreValue(environment).CLIENT_NAME}/casino/games/`
        : `${getStoreValue(environment).CASINO_GAMES_PICS}`;
    return `${url}${imageName}?${stringify(params)}`;
}

function getSubproviderLogoUrlForLogo(subproviderId: number, params = {}): string {
    const url = isB2B()
        ? `${getStoreValue(environment).IMAGES_HOST}/${getStoreValue(environment).CLIENT_NAME}/casino/providers`
        : `${getStoreValue(environment).CASINO_GAMES_PICS}providers`;
    return `${url}/${subproviderId}.png?${stringify(params)}`;
}

export function getCasinoGameBackgroundUrl(backgroundImageName: string, params = {}): string {
    const url = isB2B()
        ? `${getStoreValue(environment).IMAGES_HOST}${getStoreValue(environment).CLIENT_NAME}/casino/games/`
        : `${getStoreValue(environment).CASINO_GAMES_PICS}`;

    return `${url}${backgroundImageName}?${stringify({
        fm: 'pjpg',
        dpr: Math.min(window.devicePixelRatio, 2),
        auto: 'format',
        ...params,
    })}`;
}

export function isCasinoGameBlockedByBonus(disabledWithBonus: boolean): boolean {
    return Boolean(getStoreValue(casino.hasActiveBonus) && disabledWithBonus);
}

export function isCasinoGameUnderMaintenance(providerId: CasinoProviders, subProviderId?: CasinoSubProvider): boolean {
    const maintenance: CasinoMaintenance = getStoreValue(casino.maintenance)[providerId];

    if (!maintenance) {
        return false;
    }

    return !subProviderId || isEmpty(maintenance.subproviderIds) || maintenance.subproviderIds.includes(subProviderId);
}

export function isCasinoGameDisabled(gameLauncher: CasinoGameLauncher): boolean {
    return (
        (gameLauncher.disabledWithBonus && isCasinoGameBlockedByBonus(gameLauncher.disabledWithBonus)) ||
        isCasinoGameUnderMaintenance(gameLauncher.providerId, gameLauncher.subProviderId)
    );
}

export function getCasinoGameRoute(game: { id: number; name: string }): string {
    return `${getRoute('casino-game')}/${getCasinoGameSlug(game.name, game.id)}`;
}

export function openCasinoGame(navigationHandler, game: CasinoGame): void {
    const isAuthenticated = getStoreValue(stores.isAuthenticated);
    if (!game || isCasinoGameDisabled(game)) {
        return;
    }

    if (!hasCasinoGamePlayForFun(game) && !isAuthenticated) {
        requestLogin();
        return;
    }

    navigateToCasinoGamePage(navigationHandler, game);
}

export async function navigateToCasinoGamePage(navigationHandler, game: CasinoGame) {
    if (isMobileApp() && !getStoreValue(stores.isAuthenticated) && !hasCasinoGamePlayForFun(game)) {
        requestLogin();
        return;
    }

    const gameRoute = getCasinoGameRoute(game);
    const appType = getStoreValue(stores.appType);

    if (appType === AppType.ANDROID) {
        const androidMessage = buildAndroidNativeMessage(gameRoute);

        sendNativeEvent(androidMessage);
    } else if (appType === AppType.IOS) {
        const iOSMessage = await buildIOSNativeMessage(game);

        sendNativeEvent(iOSMessage);
    } else {
        navigationHandler(gameRoute);
    }
}

export async function sendGameUrlNativeMessageOrElseReplaceGameUrl(game: CasinoGame) {
    if (isMobileApp() && !getStoreValue(stores.isAuthenticated) && !hasCasinoGamePlayForFun(game)) {
        requestLogin();
        return;
    }

    const gameRoute = getCasinoGameRoute(game);
    const appType = getStoreValue(stores.appType);

    if (appType === AppType.ANDROID) {
        const androidMessage = buildAndroidNativeMessage(gameRoute);

        sendNativeEvent(androidMessage);
    } else if (appType === AppType.IOS) {
        const iOSMessage = await buildIOSNativeMessage(game);

        sendNativeEvent(iOSMessage);
    } else {
        window.history.pushState({}, '', gameRoute);
    }
}

async function buildIOSNativeMessage(gameLauncher: CasinoGameLauncher) {
    const appType = getStoreValue(stores.appType);
    const gameLauncherConfig = await getGameLauncherConfig(appType, gameLauncher);

    const provider =
        gameLauncher.providerId === CasinoProviders.EVOLUTION && gameLauncher.subProviderId
            ? getCasinoSubProviderName(gameLauncher.subProviderId)
            : CasinoProviders[gameLauncher.providerId]?.toLowerCase();

    return {
        type: NativeMessageEventType.GAME_LAUNCH,
        provider,
        gameCode: gameLauncher.serverGameId || 'lobby_launch',
        params: gameLauncherConfig.launchParams,
    };
}

function buildAndroidNativeMessage(gameRoute: string) {
    const gameLink = `${window.location.origin}${gameRoute}${window.location.search}`;

    return {
        type: NativeMessageEventType.GAME_LAUNCH,
        url: gameLink,
    };
}

export function hasCasinoGamePlayForFun(gameLauncher: CasinoGameLauncher): boolean {
    if (gameLauncher.subProviderId === CasinoSubProvider.EVOLUTION) {
        return false;
    }

    if (!isFeatureAvailable(FEATURE.CASINO_PLAY_FOR_FUN_ENABLED)) {
        return false;
    }

    if (gameLauncher?.id) {
        // Pragmatic Play hack (https://coolbet.atlassian.net/browse/CASINOFEAT-1985)
        // 4051 = stage
        // 4375 = prod
        if (gameLauncher?.name?.toLowerCase() === 'spaceman' && [4051, 4375].includes(gameLauncher.id)) {
            return false;
        }
        // Pragmatic Play hack (https://gan-tech.atlassian.net/browse/CCASPLAT-518)
        // not on stage
        // 7993 = prod
        if (gameLauncher?.name?.toLowerCase() === 'big bass crash' && gameLauncher.id === 7993) {
            return false;
        }
    }

    return gameLauncher.gameType !== 'live';
}

export function loadFilteredCasinoGames(): void {
    const closedGames = getStoreValue(casino.closedGames);
    const notFilteredGames = getStoreValue(casino.allGames);

    if (!isTestUser()) {
        casino.filteredGames.set(notFilteredGames.filter((game) => !Boolean(closedGames[game.id])));
    } else {
        casino.filteredGames.set(notFilteredGames);
    }
    casino.gamesById.set(keyBy(getStoreValue(casino.filteredGames), 'id'));
    casino.gamesByGroupId.set(keyBy(getStoreValue(casino.filteredGames), 'groupId'));
}

function setClosedLiveGames(liveGames: LiveGameMetaData[]): void {
    const incomingClosedGames = {};
    liveGames.forEach((liveGame) => {
        const game = getLiveGameById(liveGame.id);
        if (
            game &&
            (!liveGame.open ||
                (game?.type === CasinoGameType.BLACKJACK && !liveGame.betBehind && liveGame.availableSeats === 0))
        ) {
            incomingClosedGames[game.id] = game;
        }
    });

    casino.closedGames.set(incomingClosedGames);
}

export function manageLiveLobbyUpdates(payload: LiveGameMetaData[], raceGames: CasinoGame[]) {
    updateLiveGameMetaData(payload);
    const oldLiveClosedGames = getStoreValue(casino.closedGames);
    setClosedLiveGames(payload);
    const newLiveClosedGames = getStoreValue(casino.closedGames);
    if (!isEqual(oldLiveClosedGames, newLiveClosedGames)) {
        loadFilteredCasinoGames();
        mapCasinoGames();
        mapCasinoCategories(raceGames);
        mapCasinoProviders();
    }
}

export async function setInitialLiveGameMetaData(raceGames: CasinoGame[]) {
    manageLiveLobbyUpdates(await getLiveLobbyMetaData(), raceGames);
}

function getLiveGameById(gameId: string): CasinoGame | null {
    const allGamesByProviderAndServerId = getStoreValue(casino.allGamesByProviderAndServerId);
    const allGamesByExternalId = getStoreValue(casino.allGamesByExternalId);
    let game: CasinoGame | null = null;
    LIVE_LOBBY_PROVIDERS.every((providerId) => {
        if (allGamesByProviderAndServerId[providerId]?.[gameId]) {
            game = allGamesByProviderAndServerId[providerId][gameId];
            return false;
        }
        return true;
    });

    if (!game && allGamesByExternalId[gameId]) {
        game = allGamesByExternalId[gameId];
    }

    return game;
}

function updateLiveGameMetaData(liveGames: LiveGameMetaData[]): void {
    const gamesMetaDataById: Record<number, CasinoGameMetaData> = getStoreValue(casino.gamesMetaDataById);
    const updatedGamesMetaDataById: Record<number, CasinoGameMetaData> = {};
    liveGames.forEach((liveGame) => {
        const game = getLiveGameById(liveGame.id);
        if (game) {
            const betLimits = liveGame.betLimits?.find((betLimit) => betLimit.currency === getActiveCurrency());
            let seatsTaken: number[] | undefined = undefined;
            let results: string[] = [];
            if (game.type === CasinoGameType.BLACKJACK) {
                seatsTaken = liveGame?.seatsTaken;
            }
            if (
                game.type &&
                (
                    [
                        CasinoGameType.ROULETTE,
                        CasinoGameType.BACCARAT,
                        CasinoGameType.GAME_SHOW,
                        CasinoGameType.CRASH,
                    ] as string[]
                ).includes(game.type)
            ) {
                results = liveGame?.results;
            }
            const metaData: CasinoGameMetaData = {
                ...gamesMetaDataById[game.id],
                availableSeats: liveGame.availableSeats,
                seatedPlayers: liveGame.seatedPlayers,
                results,
                seatsTaken,
                betBehind: liveGame.betBehind,
                dealerName: liveGame.dealerName,
                imageUrl: liveGame.imageUrl,
            };

            if (betLimits) {
                metaData.minBet = betLimits.min;
                metaData.maxBet = betLimits.max;
            }

            if (game.id) {
                updatedGamesMetaDataById[game.id] = metaData;
            }
        }
    });
    updateGameMetaData(updatedGamesMetaDataById);
}

function updateGameMetaData(newMetaData: Record<number, CasinoGameMetaData>): void {
    const oldAllMetaDataGamesById = getStoreValue(casino.gamesMetaDataById);
    const newAllMetaDataGamesById = { ...oldAllMetaDataGamesById, ...newMetaData };
    if (!isEqual(oldAllMetaDataGamesById, newAllMetaDataGamesById)) {
        casino.gamesMetaDataById.set(newAllMetaDataGamesById);
    }
}
