import { Country } from '@staycool/location';
import {
    getAvailableJackpots,
    getRecentWins,
    getUserJackpots,
    optIn,
    optOut,
} from '../../microservices/casino-thrilltech-jackpots';
import { stores } from '../../stores';
import { casino } from '../../stores/casino';
import { casinoThrilltechJackpotStore } from '../../stores/casino-thrilltech-jackpot';
import { getStoreValue } from '../../stores/store/utils';
import { JackpotPotsByCurrency } from '../../types/casino-thrilltech-jackpot/types';
import { getActiveCurrency } from '../currency';
import { isFeatureAvailable } from '../feature';
import { Language, REAL_LANGUAGE } from '../language/types';
import { logger } from '../logger';
import { FEATURE } from '../types';

export async function loadThrilltechJackpots() {
    const isAuthenticated = getStoreValue(stores.isAuthenticated);
    const user = getStoreValue(stores.user);
    const ipCountry = getStoreValue(stores.ipCountry);
    const jackpotsDataById = getStoreValue(casinoThrilltechJackpotStore.jackpotsDataById);
    const currency = getActiveCurrency();

    if (!isFeatureAvailable(FEATURE.CASINO_THRILLTECH_JACKPOT)) {
        return;
    }

    try {
        const [jackpots] = await Promise.all([
            isAuthenticated && user
                ? getUserJackpots({
                      country: user.country,
                      currency,
                  })
                : getAvailableJackpots({
                      country: ipCountry as Country,
                      currency,
                  }),
            refreshRecentWins(),
        ]);

        const potValuesByJackpotId: Record<string, JackpotPotsByCurrency> = jackpots.reduce((jackpotsMap, jackpot) => {
            jackpotsMap[jackpot.externalId] = {
                ...jackpotsDataById?.[jackpot.externalId],
                [currency]: jackpot.pots.reduce((potsMap, { id, amount }) => {
                    potsMap[id] = amount;
                    return potsMap;
                }, {}),
            };
            return jackpotsMap;
        }, {});
        casinoThrilltechJackpotStore.availableJackpots.set(jackpots);
        casinoThrilltechJackpotStore.jackpotsDataById.set(potValuesByJackpotId);
    } catch (error) {
        logger.error('CasinoCasinoThrilltechJackpotService', 'loadThrilltechJackpots', error);
    }
}

export async function refreshRecentWins() {
    const isAuthenticated = getStoreValue(stores.isAuthenticated);
    if (isAuthenticated) {
        casinoThrilltechJackpotStore.recentWins.set(await getRecentWins());
    }
}

export async function thrilltechJackpotOptIn(jackpotId: number, contributionValue?: number) {
    const isAuthenticated = getStoreValue(stores.isAuthenticated);
    const user = getStoreValue(stores.user);

    if (!isAuthenticated || !user) {
        return;
    }

    try {
        await optIn({ jackpotId, contributionValue, userCurrency: getActiveCurrency() });
        await loadThrilltechJackpots();
    } catch (error) {
        logger.error('CasinoCasinoThrilltechJackpotService', 'thrilltechJackpotOptIn', error);
    }
}

export async function thrilltechJackpotOptOut(jackpotId: number) {
    const isAuthenticated = getStoreValue(stores.isAuthenticated);
    const user = getStoreValue(stores.user);

    if (!isAuthenticated || !user) {
        return;
    }

    try {
        await optOut(jackpotId);
        await loadThrilltechJackpots();
    } catch (error) {
        logger.error('CasinoCasinoThrilltechJackpotService', 'thrilltechJackpotOptOut', error);
    }
}

export function getJackpotByGameId(gameId?: number) {
    const availableJackpots = getStoreValue(casinoThrilltechJackpotStore.availableJackpots);
    const gamesById = getStoreValue(casino.gamesById);
    const groupId = gameId && gamesById[gameId]?.groupId;
    const sortedJackpots = [...availableJackpots].sort(({ optInStatus: aStatus }, { optInStatus: bStatus }) =>
        aStatus === bStatus ? 0 : aStatus ? -1 : 1,
    );
    return groupId ? sortedJackpots.find(({ gameGroupIds }) => gameGroupIds.includes(groupId)) : undefined;
}

export function getAvailableLanguage() {
    const availableLanguages = [REAL_LANGUAGE.ENGLISH, REAL_LANGUAGE.SPANISH] as Language[];
    const language = getStoreValue(stores.language);
    return availableLanguages.includes(language) ? language : REAL_LANGUAGE.ENGLISH;
}
