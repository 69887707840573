import React from 'react';
import Wrapper from './styles';
import DevelopmentToolbarB2B from './b-2-b/DevelopmentToolbarB2B';
import UiGroup from '../../ui/group/UiGroup';
import DevelopmentErrors from '../errors/DevelopmentErrors';
import DevelopmentToolbarHorseRacing from './horse-racing/DevelopmentToolbarHorseRacing';
import DevelopmentToolbarQuotes from './quotes/DevelopmentToolbarQuotes';
import UiBox from '../../ui/box/UiBox';
import DevelopmentToolbarOpenbet from './openbet/DevelopmentToolbarOpenbet';

export default function DevelopmentToolbar() {
    return (
        <Wrapper>
            <h1>Developer toolbar</h1>

            <div className="row">
                <div className="column errors">
                    <DevelopmentErrors />
                </div>
                <div className="column">
                    <UiGroup vertical>
                        <DevelopmentToolbarQuotes />
                        <UiBox>
                            <DevelopmentToolbarB2B />
                            <DevelopmentToolbarHorseRacing />
                            <DevelopmentToolbarOpenbet />
                        </UiBox>
                    </UiGroup>
                </div>
            </div>
        </Wrapper>
    );
}
