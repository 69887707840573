import React from 'react';
import Wrapper from './styles';
import UiAlert from '../../ui/alert/UiAlert';
import DevelopmentError from '../error/DevelopmentError';
import { stores } from '../../../stores';
import { useStore } from '../../../hooks/useStore';

export default function DevelopmentErrors() {
    const [{ status, errors }] = useStore(stores.development);

    return (
        <Wrapper>
            {Object.keys(errors).length > 0 ? (
                <UiAlert failure>
                    Not good, something is wrong... {Object.keys(errors).length} files with errors:
                </UiAlert>
            ) : (
                <UiAlert success>Code check ok</UiAlert>
            )}
            <div data-test="has-errors">
                {status === 'error' &&
                    Object.keys(errors).map((key) => <DevelopmentError key={key} error={errors[key]} />)}
            </div>
        </Wrapper>
    );
}
