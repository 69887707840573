import React, { useState } from 'react';
import Wrapper from './styles';
import DevelopmentToolbar from '../toolbar/DevelopmentToolbar';
import { stores } from '../../../stores';
import UiModal from '../../ui/modal/UiModal';
import { media } from '../../../stores/media/media';
import { useStore } from '../../../hooks/useStore';

export default function DevelopmentSettings() {
    const [isOpen, setIsOpen] = useState(false);
    const [{ errors = {} }] = useStore(stores.development);
    const summarizedErrorCount = Object.keys(errors)?.length || 0;
    const [{ isPhone }] = useStore(media);

    return (
        <>
            <Wrapper>
                <span
                    onClick={() => setIsOpen(!isOpen)}
                    data-test="mikk-is-looking-at-you"
                    className="mikk"
                    title="Mikk"
                    role="img"
                    aria-label="Mikk"
                >
                    👨‍💻
                </span>

                {summarizedErrorCount > 0 && <div className="badge">{summarizedErrorCount}</div>}
            </Wrapper>
            <UiModal open={isOpen} onClose={() => setIsOpen(false)} maxWidth={isPhone ? 375 : 1200}>
                <DevelopmentToolbar />
            </UiModal>
        </>
    );
}
