import orderBy from 'lodash/orderBy';
import { translate } from '../translate';
import { CasinoFilterProperties, placeholderCasinoFilter } from './casino-preferences';
import { CasinoGame, Filter, GameType } from './types';

interface CasinoFilter {
    providers: number[];
    subProviders: number[];
    otherProviders: boolean;
    types: number[];
    gameTypes: number[];
    rtps: number[];
    maxWinMultipliers: number[];
    volatilities: string[];
    features: number[];
    isGameState: boolean;
    minBets: number[];
    operatingLanguageIds: number[];
}

interface CasinoCategoryFilter {
    customFilter?: Filter;
    studioId: number | null;
}

export const enum CasinoFilterSaveFormStatusType {
    INITIAL_STATE = 'INITIAL_STATE',
    READY_TO_SUBMIT = 'READY_TO_SUBMIT',
    SUBMITTING = 'SUBMITTING',
    SUBMITTED_SUCCESSFULLY = 'SUBMITTED_SUCCESSFULLY',
}

export const CasinoFilterGameType = {
    0: {
        id: 0,
        filterGame: (game) => game.type && game.type === 'Slots',
        getDisplayName: () => translate('Slots', 'casino.filter.type'),
    },
    1: {
        id: 1,
        filterGame: (game) =>
            game.type &&
            game.gameType !== 'live' &&
            ['Blackjack', 'Roulette', 'Baccarat', 'Puntobanco', 'Poker', 'Dice', 'Gameshow'].includes(game.type),
        getDisplayName: () => translate('Table Games', 'casino.filter.type'),
    },
    2: {
        id: 2,
        filterGame: (game) => game.type && game.type === 'Video Poker',
        getDisplayName: () => translate('Video Poker', 'casino.filter.type'),
    },
    3: {
        id: 3,
        filterGame: (game) => game.type && ['Scratch Card', 'Bingo'].includes(game.type),
        getDisplayName: () => translate('Other', 'casino.filter.type'),
    },
};

export const CasinoFilterLiveGameType = {
    10: {
        id: 10,
        filterGame: (game) => game?.type === 'Poker',
        getDisplayName: () => translate(['Poker', 'Poker'], 'casino.filter.type'),
    },
    11: {
        id: 11,
        filterGame: (game) => game?.type === 'Roulette',
        getDisplayName: () => translate(['Roulette', 'Roulette'], 'casino.filter.type'),
    },
    12: {
        id: 12,
        filterGame: (game) => game?.type === 'Baccarat',
        getDisplayName: () => translate(['Baccarat', 'Baccarat'], 'casino.filter.type'),
    },
    13: {
        id: 13,
        filterGame: (game) => game?.type === 'Blackjack',
        getDisplayName: () => translate(['Blackjack', 'Blackjack'], 'casino.filter.type'),
    },
    14: {
        id: 14,
        filterGame: (game) => game?.type === 'Gameshow',
        getDisplayName: () => translate(['Gameshow', 'Gameshow'], 'casino.filter.type'),
    },
    15: {
        id: 15,
        filterGame: (game) => game?.type === 'Dice',
        getDisplayName: () => translate(['Dice', 'Dice'], 'casino.filter.type'),
    },
    16: {
        id: 16,
        filterGame: (game) => game.gameType === 'live',
        getDisplayName: () => translate('Live Casino', 'casino.filter.type'),
    },
};

export const CasinoFilterRTP = {
    0: {
        id: 0,
        filterGame: (game) => game.rtp && parseFloat(game.rtp) > 96.5,
        getDisplayName: () => translate('High', 'casino.filter.rtp'),
        getComment: () => translate('comment', 'casino.filter.rtp.high'),
    },
    1: {
        id: 1,
        filterGame: (game) => game.rtp && parseFloat(game.rtp) >= 95 && parseFloat(game.rtp) <= 96.5,
        getDisplayName: () => translate('Medium', 'casino.filter.rtp'),
        getComment: () => translate('comment', 'casino.filter.rtp.medium'),
    },
    2: {
        id: 2,
        filterGame: (game) => game.rtp && parseFloat(game.rtp) < 95,
        getDisplayName: () => translate('Low', 'casino.filter.rtp'),
        getComment: () => translate('comment', 'casino.filter.rtp.low'),
    },
};

export const CasinoFilterMaxWinMultiplier = {
    0: {
        id: 0,
        filterGame: (game) => game.xWin && game.xWin >= 10000,
        getDisplayName: () => translate('10,000+', 'casino.filter.max-win-multiplier'),
    },
    1: {
        id: 1,
        filterGame: (game) => game.xWin && game.xWin >= 5000 && game.xWin <= 9999,
        getDisplayName: () => translate('5,000 - 9,999', 'casino.filter.max-win-multiplier'),
    },
    2: {
        id: 2,
        filterGame: (game) => game.xWin && game.xWin < 5000,
        getDisplayName: () => translate('Less than 5000', 'casino.filter.max-win-multiplier'),
    },
};

export const CasinoFilterMinBet = {
    0: {
        id: 0,
        filterGame: (game) => game.minBet && game.minBet >= 1000,
        getDisplayName: () => translate('1,000+', 'casino.filter.min-bet'),
    },
    1: {
        id: 1,
        filterGame: (game) => game.minBet && game.minBet >= 100 && game.minBet <= 999,
        getDisplayName: () => translate('100 - 999', 'casino.filter.min-bet'),
    },
    2: {
        id: 2,
        filterGame: (game) => game.minBet && game.minBet >= 50 && game.minBet <= 99.99,
        getDisplayName: () => translate('50 - 99,99', 'casino.filter.min-bet'),
    },
    3: {
        id: 3,
        filterGame: (game) => game.minBet >= 25 && game.minBet <= 49.9,
        getDisplayName: () => translate('25 - 49,99', 'casino.filter.min-bet'),
    },
    4: {
        id: 4,
        filterGame: (game) => game.minBet >= 10 && game.minBet <= 24.99,
        getDisplayName: () => translate('10 - 24,99', 'casino.filter.min-bet'),
    },
    5: {
        id: 5,
        filterGame: (game) => game.minBet >= 1 && game.minBet <= 9.99,
        getDisplayName: () => translate('1 - 9,99', 'casino.filter.min-bet'),
    },
    6: {
        id: 6,
        filterGame: (game) => game.minBet < 1,
        getDisplayName: () => translate('Less than 1', 'casino.filter.min-bet'),
    },
};

enum Features {
    LINES_SPECIAL = 1,
    RTP_SPECIAL = 2,
    FREE_SPINS_SUPPORT = 14,
    CLUSTER_PAYS = 15,
    RTP_VARIANT = 52,
    MAX_WIN_MULTIPLIER_SPECIAL = 53,
}

export const CasinoFilterExcludedFeatures = [
    Features.LINES_SPECIAL,
    Features.RTP_SPECIAL,
    Features.FREE_SPINS_SUPPORT,
    Features.RTP_VARIANT,
    Features.MAX_WIN_MULTIPLIER_SPECIAL,
];

function getDefaultFilter(): CasinoFilter {
    return {
        providers: [],
        subProviders: [],
        otherProviders: false,
        types: [],
        gameTypes: [],
        rtps: [],
        maxWinMultipliers: [],
        volatilities: [],
        features: [],
        isGameState: false,
        minBets: [],
        operatingLanguageIds: [],
    };
}

export function getCasinoFilter(): CasinoFilter {
    return getDefaultFilter();
}

export function applyFilter(
    gameTypesById: { [p: number]: GameType },
    filter: CasinoFilterProperties,
    games: CasinoGame[],
) {
    const {
        studios,
        types,
        rtps,
        maxWinMultipliers,
        volatilities,
        features,
        minBets,
        operatingLanguages,
        gameTypes,
        themes,
    } = filter;
    let filteredGames = games;

    if (studios?.length) {
        filteredGames = filteredGames.filter((game) => studios.some((studioId) => game?.studioId === studioId));
    }

    if (types?.length) {
        filteredGames = filteredGames.filter((game) =>
            types.some(
                (typeId) =>
                    CasinoFilterGameType[typeId]?.filterGame(game) ||
                    CasinoFilterLiveGameType[typeId]?.filterGame(game),
            ),
        );
    }

    if (rtps?.length) {
        filteredGames = filteredGames.filter((game) => rtps.some((rtp) => CasinoFilterRTP[rtp].filterGame(game)));
    }

    if (maxWinMultipliers?.length) {
        filteredGames = filteredGames.filter((game) =>
            maxWinMultipliers.some((maxWinMultiplier) =>
                CasinoFilterMaxWinMultiplier[maxWinMultiplier].filterGame(game),
            ),
        );
    }

    if (volatilities?.length) {
        filteredGames = filteredGames.filter((game) => game.volatility && volatilities.includes(game.volatility));
    }

    if (features?.length) {
        filteredGames = filteredGames.filter((game) =>
            features.some((featureId) => game.properties.includes(featureId)),
        );
    }

    if (minBets?.length) {
        filteredGames = filteredGames.filter((game) =>
            minBets.some((minBet) => CasinoFilterMinBet[minBet]?.filterGame(game)),
        );
    }

    if (operatingLanguages?.length) {
        filteredGames = filteredGames.filter((game) =>
            operatingLanguages.some((operatingLanguageId) => game?.operatingLanguageId === operatingLanguageId),
        );
    }

    if (gameTypes?.length) {
        filteredGames = filteredGames.filter((game) =>
            gameTypes.some((gameTypeId) => game?.type === gameTypesById[gameTypeId]?.typeCom),
        );
    }

    if (themes?.length) {
        filteredGames = filteredGames.filter((game) => themes.some((themeId) => game?.themes.includes(themeId)));
    }

    return orderBy(filteredGames, (game) => game?.releaseDate || '', 'desc');
}

export function prepareFilterFromCustomCategoryFilter(categoryFilter: CasinoCategoryFilter): CasinoFilterProperties {
    const filter: CasinoFilterProperties = JSON.parse(JSON.stringify(placeholderCasinoFilter.filter));
    if (categoryFilter.studioId) {
        filter.studios.push(categoryFilter.studioId);
    }

    switch (categoryFilter.customFilter?.type) {
        case 'feature':
            categoryFilter.customFilter.id && filter.features.push(categoryFilter.customFilter.id);
            break;
        case 'type':
            categoryFilter.customFilter.id && filter?.gameTypes?.push(categoryFilter.customFilter.id);
            break;
        case 'theme':
            categoryFilter.customFilter.id && filter?.themes?.push(categoryFilter.customFilter.id);
            break;
        case 'volatility':
            categoryFilter.customFilter.name && filter.volatilities.push(categoryFilter.customFilter.name);
            break;
        case 'rtp':
            categoryFilter.customFilter.name && filter.rtps.push(parseInt(categoryFilter.customFilter.name));
            break;
        case 'xWin':
            categoryFilter.customFilter.name &&
                filter.maxWinMultipliers.push(parseInt(categoryFilter.customFilter.name));
            break;
        default:
            break;
    }

    return filter;
}
